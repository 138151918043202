<template>
  <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
  />
</template>

<script>
import {Line as LineChartGenerator} from 'vue-chartjs/legacy';
import numeral from 'numeral';
import {startsWith} from 'lodash';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    zoomPlugin,
);

const HIT_PV_KEY = 'hit_pv';
const MISS_PV_KEY = 'miss_pv';

const SUCCESSFUL_RESPONSE_START = '2';
const REDIRECTION_RESPONSE_START = '3';
const CLIENT_ERROR_RESPONSE_START = '4';
const SERVER_ERROR_RESPONSE_START = '5';

const CDN_2XX_DATASET_INDEX = 0;
const CDN_3XX_DATASET_INDEX = 1;
const CDN_4XX_DATASET_INDEX = 2;
const CDN_5XX_DATASET_INDEX = 3;
const UPSTREAM_2XX_DATASET_INDEX = 4;
const UPSTREAM_3XX_DATASET_INDEX = 5;
const UPSTREAM_4XX_DATASET_INDEX = 6;
const UPSTREAM_5XX_DATASET_INDEX = 7;

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    intervals: {
      default: [],
    },
    intervalTimeUnit: {
      type: String,
      default: 'd',
    },
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    labels: function () {
      if (this.intervalTimeUnit === 'd') {
        return this.intervals.map((i) => {
          const parts = i.second.split(' ');

          return parts[0];
        });
      }

      let lastDate = null;
      const labels = [];

      for (let i of this.intervals) {
        let returnDate = false;
        const [datePart, timePart] = i.second.split(' ');
        if (lastDate !== datePart) {
          lastDate = datePart;
          returnDate = true;
        }

        if (returnDate)
          labels.push(i.second);
        else
          labels.push(timePart);
      }

      return labels;
    },
    cdn2xxDataset: function () {
      return {
        label: this.$t('CDN0', ['2XX']),
        data: this.getSeriesTotal(SUCCESSFUL_RESPONSE_START, HIT_PV_KEY),
        backgroundColor: '#1CD347',
        borderColor: '#1CD347',
      };
    },
    cdn3xxDataset: function () {
      return {
        label: this.$t('CDN0', ['3XX']),
        data: this.getSeriesTotal(REDIRECTION_RESPONSE_START, HIT_PV_KEY),
        backgroundColor: '#00f9ff',
        borderColor: '#00f9ff',
      };
    },
    cdn4xxDataset: function () {
      return {
        label: this.$t('CDN0', ['4XX']),
        data: this.getSeriesTotal(CLIENT_ERROR_RESPONSE_START, HIT_PV_KEY),
        backgroundColor: '#ff8200',
        borderColor: '#ff8200',
      };
    },
    cdn5xxDataset: function () {
      return {
        label: this.$t('CDN0', ['5XX']),
        data: this.getSeriesTotal(SERVER_ERROR_RESPONSE_START, HIT_PV_KEY),
        backgroundColor: '#ff0000',
        borderColor: '#ff0000',
      };
    },
    upstream2xxDataset: function () {
      return {
        label: this.$t('Upstream0', ['2XX']),
        data: this.getSeriesTotal(SUCCESSFUL_RESPONSE_START, MISS_PV_KEY),
        backgroundColor: '#0026CD',
        borderColor: '#0026CD',
      };
    },
    upstream3xxDataset: function () {
      return {
        label: this.$t('Upstream0', ['3XX']),
        data: this.getSeriesTotal(CLIENT_ERROR_RESPONSE_START, MISS_PV_KEY),
        backgroundColor: '#CB00B8',
        borderColor: '#CB00B8',
      };
    },
    upstream4xxDataset: function () {
      return {
        label: this.$t('Upstream0', ['4XX']),
        data: this.getSeriesTotal(CLIENT_ERROR_RESPONSE_START, MISS_PV_KEY),
        backgroundColor: '#858585',
        borderColor: '#858585',
      };
    },
    upstream5xxDataset: function () {
      return {
        label: this.$t('Upstream0', ['5XX']),
        data: this.getSeriesTotal(SERVER_ERROR_RESPONSE_START, MISS_PV_KEY),
        backgroundColor: '#a17551',
        borderColor: '#a17551',
      };
    },
    chartData: function () {
      return {
        labels: this.labels,
        datasets: [
          this.cdn2xxDataset,
          this.cdn3xxDataset,
          this.cdn4xxDataset,
          this.cdn5xxDataset,
          this.upstream2xxDataset,
          this.upstream3xxDataset,
          this.upstream4xxDataset,
          this.upstream5xxDataset,
        ],
      };
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            // we need this padding to align to the right axis of the overview graph
            right: 100,
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.$t('HttpResponseStatus'),
          },
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const statusLabels = [`${context.dataset.label}: ${context.parsed.y}`];
                let statusData = [];
                const interval = this.intervals[context.dataIndex];

                if (context.datasetIndex === CDN_2XX_DATASET_INDEX) {
                  statusData = this.getStatusData(interval, SUCCESSFUL_RESPONSE_START);
                } else if (context.datasetIndex === CDN_3XX_DATASET_INDEX) {
                  statusData = this.getStatusData(interval, REDIRECTION_RESPONSE_START);
                } else if (context.datasetIndex === CDN_4XX_DATASET_INDEX) {
                  statusData = this.getStatusData(interval, CLIENT_ERROR_RESPONSE_START);
                } else if (context.datasetIndex === CDN_5XX_DATASET_INDEX) {
                  statusData = this.getStatusData(interval, SERVER_ERROR_RESPONSE_START);
                } else if (context.datasetIndex === UPSTREAM_2XX_DATASET_INDEX) {
                  statusData = this.getStatusData(interval, SUCCESSFUL_RESPONSE_START, MISS_PV_KEY);
                } else if (context.datasetIndex === UPSTREAM_3XX_DATASET_INDEX) {
                  statusData = this.getStatusData(interval, REDIRECTION_RESPONSE_START, MISS_PV_KEY);
                } else if (context.datasetIndex === UPSTREAM_4XX_DATASET_INDEX) {
                  statusData = this.getStatusData(interval, CLIENT_ERROR_RESPONSE_START, MISS_PV_KEY);
                } else if (context.datasetIndex === UPSTREAM_5XX_DATASET_INDEX) {
                  statusData = this.getStatusData(interval, SERVER_ERROR_RESPONSE_START, MISS_PV_KEY);
                }

                for (const s of statusData) {
                  statusLabels.push(`HTTP ${s.status}: ${s.value}`);
                }

                return statusLabels;
              }
            }
          },
        },
        scales: {
          y: {
            type: 'linear',
            position: 'left',
            grid: {
              drawOnChartArea: false,
            },
            afterFit(scale) {
              // we are setting the width of the scale to 120 here to align
              // with the overview graph y-axis. in the overview graph, the 
              // cache hit ration and requests y-axis are set to 60 width each.
              // so having a 120 here would align the two graph.
              scale.width = 120;
            },
            margins: {
              right: 60,
            }
          },
        },
      }
    }
  },
  methods: {
    getSeriesTotal(seriesStart, pvKey = HIT_PV_KEY) {
      const data = [];
      for (let i of this.intervals) {
        const keys = Object.keys(i.status);
        let seriesTotal = 0;

        for (let k of keys) {
          if (!startsWith(k, seriesStart)) {
            continue;
          }

          seriesTotal += i.status[k][pvKey];
        }

        data.push(seriesTotal);
      }

      return data;
    },
    getStatusData(interval, seriesStart, pvKey = HIT_PV_KEY) {
      const keys = Object.keys(interval.status);
      const statusData = [];
      for (let k of keys) {
        if (!startsWith(k, seriesStart)) {
          continue;
        }
        const value = interval['status'][k][pvKey];
        if (value === 0) {
          continue;
        }

        statusData.push({status: k, value: value});
      }

      return statusData;
    },
  }
}
</script>
