<template>
  <div>
    <CDataTable
        :items="tableItems"
        :fields="fields"
        table-filter
        items-per-page-select
        :items-per-page="100"
        :items-per-page-select='{"label": $t("NumberOfItemsPerPage"), "values": [100,250,1000]}'
        hover
        sorter
        :sorterValue="{column: 'total_traffic', asc: false}"
        pagination
        ref="tableTraffic"
        :tableFilterValue.sync="search"
    >
      <template #total_traffic="{item}">
        <td>{{ formatTraffic(item) }}</td>
      </template>
      <template #total_requests="{item}">
        <td>{{ formatRequests(item) }}</td>
      </template>

      <template v-slot:footer>
        <tfoot>
          <tr>
            <th>{{ $t('Total') }}</th>
            <th></th>
            <th id="totalTrafficId">{{ totalTraffic }}</th>
            <th id="totalRequestId">{{ totalRequest }}</th>
          </tr>
        </tfoot>
      </template>
    </CDataTable>
    <div class="float-right">
    <a-button @click="exportTraffic"
              icon="download" class="mr-2 mt-1">{{ $t('Download Breakdown') }}
    </a-button></div>
  </div>
</template>

<script>
import XLSX from "xlsx";
import numeral from "numeral";
import {UNCOVERED_NETWORK_TRAFFIC} from "@/utilities/constants";

function addUncoveredNetworkTraffic(total) {
  return total + (total * (UNCOVERED_NETWORK_TRAFFIC / 100));
}

function computeTableColumnTotal(colNumber)
{
  var result = 0;
  try {
    var tableElem = window.document.getElementsByClassName("table")[0];
    if (tableElem !== undefined) {
      var tableBody = tableElem.getElementsByTagName("tbody").item(0);
      var i;
      var howManyRows = tableBody.rows.length;

      for (i=0; i<(howManyRows); i++)
      {
          var thisTrElem = tableBody.rows[i];
          var thisTdElem = thisTrElem.cells[colNumber];
          var thisTextNode = thisTdElem.childNodes.item(0);
          var thisNumber = parseFloat(thisTextNode.data);
          if (!isNaN(thisNumber))
            result += thisNumber;
      }
    }
  }
  catch (ex)
  {
     result = 0;
  }
  finally
  {
     return result;
  }
}

export default {
  props: ['domains', 'trafficData'],
  data() {
    return {
      fields: [
        {
          key: 'domain',
          label: this.$t('Domain')
        },
        {
          key: 'shadow',
          label: this.$t('Shadow')
        },
        {
          key: 'total_traffic',
          label: this.$t('domain.TotalTraffic')
        },
        {
          key: 'total_requests',
          label: this.$t('domain.TotalRequests')
        }
      ],
      search: null
    }
  },
  watch: {
    search() {
      setTimeout(function(){
        let totalR = computeTableColumnTotal(3);
        var totalRequestElem = window.document.getElementById("totalRequestId");
        if (totalRequestElem) {
          totalRequestElem.innerHTML = totalR;
        }
        let totalT = computeTableColumnTotal(2);
        totalT = totalT.toFixed(2)
        var totalTrafficElem = window.document.getElementById("totalTrafficId");
        if (totalTrafficElem) {
          totalTrafficElem.innerHTML = totalT + " KB";
        }
      }, 1000);
    }
  },
  computed: {
    totalTraffic() {
      const total = this.tableItems.map(x => x.total_traffic).reduce((a, b) => a + b, 0);
      return numeral(addUncoveredNetworkTraffic(total)).format('0.00 b');
    },
    totalRequest() {
      const total = this.tableItems.map(x => x.total_requests).reduce((a, b) => a + b, 0);
      return total;
    },
    tableItems: function () {
      const domainTraffic = [];
      for (let name of Object.keys(this.trafficData)) {
        if (name !== 'null') {
          let domain = {
            domain: name,
            shadow: null,
            total_traffic: 0,
            total_requests: 0
          };

          if (!this.trafficData[name]) {
            domainTraffic.push(domain);
            continue;
          }
          
          const traffic = this.trafficData[name].sort(this.dynamicSort('shadow'));
          for (let t of traffic) {
            if (domain.shadow != null && t.shadow != domain.shadow) {
              domainTraffic.push(domain);
              domain = {
                domain: name,
                shadow: null,
                total_traffic: 0,
                total_requests: 0
              };
            };
            domain.total_traffic += t.total_traffic;
            domain.total_requests += t.total_request;
            domain.shadow = t.shadow;
          }
          domainTraffic.push(domain);
        }
      }

      return domainTraffic;
    }
  },
  methods: {
    dynamicSort(property) {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },
    formatTraffic(item) {
      return numeral(addUncoveredNetworkTraffic(item.total_traffic)).format('0.00 b');
    },
    formatRequests(item) {
      return numeral(item.total_requests).format('0,0');
    },
    exportTraffic() {
      const headerOrder = [];
      const headers = [];
      for (let field of this.fields) {
        headers.push(field.label);
        headerOrder.push(field.key);
      }

      const ws = XLSX.utils.aoa_to_sheet([headers]);

      let data = this.tableItems;
      data.sort((a,b) => (a.total_traffic < b.total_traffic) ? 1 : ((b.total_traffic < a.total_traffic) ? -1 : 0));
      data = data.map(item => {
        const obj = Object.assign({}, item);
        obj.total_traffic = this.formatTraffic(obj);
        return obj;
      });
      data.push({
        domain: this.$t('Total'),
        total_traffic: this.totalTraffic,
        total_requests: this.totalRequest
      });
      

      XLSX.utils.sheet_add_json(ws,data, {header: headerOrder, skipHeader: true, origin: 'A2'});
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Traffic');

      XLSX.writeFile(wb, 'export-traffic.xlsx');
    }
  }
}
</script>
