<template>
  <div class="px-1 py-3">
    <div class="d-flex">
      <div>
        <h3 class="font-small font-weight-normal">
          {{ $t('Domain') }}
        </h3>
        <div>
          <multiselect v-model="selectedDomain"
                       :options="graphDomainSelectorOptions"
                       label="name"
                       track-by="pk"
                       :loading="domainData.loading"
                       :close-on-select="true"
                       :allow-empty="false"
                       :selectedLabel="''"
                       :selectLabel="''"
                       :deselectLabel="''"
                       @select="handleDomainSelect"
                       @search-change="searchDomain">
            <template slot="option" slot-scope="props">
              <span v-if="props.option.pk !== allToken" class="option__title">{{ props.option.name }}</span>
              <span v-if="props.option.pk === allToken" class="option__title all">
                <span>{{ $t('domain.AllDomains') }}</span>
              </span>
            </template>
            <template slot="afterList">
              <span class="load-more" @click="$emit('loadMoreDomain')" v-if="domainData.hasNext">{{
                  $t('load_more')
                }}</span>
            </template>
            <span slot="noResult">{{ $t('message.domain_not_found') }}</span>
          </multiselect>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3">
      <div class="w-50">
        <h3 class="font-small font-weight-normal">
          {{ $t('Range') }}:
          <span>{{ timeRangeDisplay }}</span>
        </h3>
        <div>
          <a-button @click="adjustTimeRange(adjustments.LAST_24_HOURS)" class="mr-2 mt-1">{{
              $t('time_picker.Last 24 hours')
            }}
          </a-button>
          <a-button @click="adjustTimeRange(adjustments.LAST_7_DAYS)" class="mr-2 mt-1">{{
              $t('time_picker.Last 7 days')
            }}
          </a-button>
          <a-button @click="adjustTimeRange(adjustments.LAST_30_DAYS)" class="mr-2 mt-1">{{
              $t('time_picker.Last 30 days')
            }}
          </a-button>
          <a-button @click="showCustomRangePicker = true" class="mr-2 mt-1">{{ $t('Custom') }}</a-button>
        </div>
      </div>
      <div class="">
        <h3 class="font-small font-weight-normal">
          {{ $t('Interval') }}:
          <span>{{ interval + interval_time_unit }}</span>
        </h3>
        <div>
          <a-button @click="handleIntervalUnitChange(timeUnits.MINUTES)"
                    :disabled="isMinuteIntervalDisabled"
                    class="mr-2 mt-1">{{
              $t('time_picker.15 Minutes')
            }}
          </a-button>
          <a-button @click="handleIntervalUnitChange(timeUnits.HOUR)"
                    :disabled="isHourIntervalDisabled"
                    class="mr-2 mt-1">{{
              $t('time_picker.Hour')
            }}
          </a-button>
          <a-button @click="handleIntervalUnitChange(timeUnits.DAY)" class="mr-2 mt-1">{{
              $t('time_picker.Day')
            }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3">
      <div class="w-100">
        <h3 class="font-small font-weight-normal">
          {{ $t('View') }}
        </h3>
        <div>
          <a-button @click="dataView = graphViewKey" class="mr-2 mt-1" icon="line-chart">{{ $t('Graph') }}</a-button>
          <a-button @click="dataView = tableViewKey" class="mr-2 mt-1" icon="table">{{ $t('Tabular') }}</a-button>
        </div>
      </div>
      <div>
        <h3 class="font-small font-weight-normal">&nbsp;</h3>
        <div class="d-flex">
          <a-button @click="download"
                    :disabled="isDownloadDisabled"
                    icon="download" class="mr-2 mt-1">{{ $t('DownloadCSV') }}
          </a-button>

          <a-button @click="fetchGraphData" :loading="loading" icon="reload" class="mt-1">
            {{ $t('Refresh') }}
          </a-button>
        </div>
      </div>
    </div>

    <div class="mt-2">
      <div v-if="dataView === graphViewKey">
        <a-card>
          <TrafficMultiLine :intervals="intervals" :interval-time-unit="interval_time_unit"/>
        </a-card>
      </div>
      <div v-if="dataView === tableViewKey">
        <TrafficTable :intervals="intervals" :interval-time-unit="interval_time_unit"/>
      </div>
    </div>

    <div class="mt-2">
      <div v-if="dataView === graphViewKey">
        <a-card>
          <HttpResponseStatusMultiLine :intervals="http_response_intervals" :interval-time-unit="interval_time_unit"/>
        </a-card>
      </div>
      <div v-if="dataView === tableViewKey">
        <HttpResponseStatusTable :intervals="httpResponseStatusSeriesInterval"
                                 :interval-time-unit="interval_time_unit"/>
      </div>
    </div>

    <DateRangePicker :show="showCustomRangePicker"
                     @change="handleDateRangePickerChange"
                     @close="hideCustomRangePicker"/>

    <hr style="margin-top: 50px;">

    <div>
      <header>
        <h5>{{ $t('message.BreakdownPerDomain') }}</h5>
        <p>{{ $t('message.TotalTrafficAndRequestsForEachDomain') }}</p>
      </header>

      <OverviewDomainsTable :domains="domainData.domains"
                            :traffic-data="trafficDomainData"
                            ref="overviewDomain"/>
    </div>
  </div>
</template>

<style scope>
.font-small {
  font-size: 0.8rem;
}
</style>

<script>
import {format, differenceInHours} from 'date-fns';
import moment from 'moment';
import Multiselect from 'vue-multiselect';

import TrafficMultiLine from '@/views/domain/charts/TrafficMultiLine.vue';
import HttpResponseStatusMultiLine from '@/views/domain/charts/HttpResponseStatusMultiLine.vue';
import HttpResponseStatusTable from '@/views/domain/charts/HttpResponseStatusTable.vue';
import DateRangePicker from '@/views/domain/charts/DateRangePicker.vue';
import axios from '@/plugins/axios';
import TrafficTable from '@/views/domain/charts/TrafficTable.vue';
import XLSX from 'xlsx';
import numeral from 'numeral';
import {startsWith} from 'lodash';
import {EventBus, Events} from "@/plugins/event-bus.js";
import OverviewDomainsTable from "@/views/domain/charts/OverviewDomainsTable";

const MINUTES_IN_DAY = 1440;

const TIME_ADJUSTMENTS = {
  LAST_24_HOURS: MINUTES_IN_DAY,
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_30_DAYS: 'LAST_30_DAYS',
  CUSTOM: 'CUSTOM',
};

const INTERVAL_TIME_UNITS = {
  MINUTES: 'm',
  HOUR: 'h',
  DAY: 'd',
};

const allToken = '__all__';
const graphViewKey = 'graph';
const tableViewKey = 'table';

export default {
  components: {
    TrafficTable,
    TrafficMultiLine,
    Multiselect,
    HttpResponseStatusMultiLine,
    HttpResponseStatusTable,
    DateRangePicker,
    OverviewDomainsTable
  },
  mounted() {
    this.adjustTimeRange(TIME_ADJUSTMENTS.LAST_24_HOURS);
  },
  props: ['domainData', 'searchDomain'],
  data() {
    return {
      selectedDomain: {
        pk: allToken,
        name: this.$t('domain.AllDomains')
      },
      showCustomRangePicker: false,
      timeRange: [moment(), moment()],
      interval: 1,
      interval_time_unit: 'd',
      intervals: [],
      http_response_intervals: [],
      allToken: allToken,
      loading: false,
      dataView: 'graph',
      graphViewKey,
      tableViewKey,
      trafficDomainData: []
    };
  },
  beforeDestroy() {
    EventBus.$off(Events.DOWNLOAD_CERTIFICATE);
  },
  computed: {
    graphDomainSelectorOptions: function () {
      if (!this.userCname)
        return this.domainData.domains;

      return [{pk: allToken, name: this.$t('domain.AllDomains')}, ...this.domainData.domains];
    },
    userCname: function () {
      return this.$store.getters.cname;
    },
    timeRangeDisplay: function () {
      return format(this.timeRange[0].toDate(), 'yyyy-MM-dd') + ' ~ ' + format(this.timeRange[1].toDate(), 'yyyy-MM-dd');
    },
    adjustments: function () {
      return TIME_ADJUSTMENTS;
    },
    timeUnits: function () {
      return INTERVAL_TIME_UNITS;
    },
    isMinuteIntervalDisabled: function () {
      const hoursDiff = differenceInHours(this.timeRange[1].toDate(), this.timeRange[0].toDate());

      return hoursDiff > 24;
    },
    isHourIntervalDisabled: function () {
      return this.isMinuteIntervalDisabled;
    },
    isDownloadDisabled: function () {
      if (this.loading) {
        return true;
      }

      return this.intervals.length === 0 && this.http_response_intervals.length === 0;
    },
    // flatten the status to 2xx, 3xx, 4xx, 5xx
    httpResponseStatusSeriesInterval: function () {
      return this.http_response_intervals.map((i) => {
        const seriesData = {second: i.second, status: {}};

        let cdn2xx = 0;
        let cdn3xx = 0;
        let cdn4xx = 0;
        let cdn5xx = 0;
        let upstream2xx = 0;
        let upstream3xx = 0;
        let upstream4xx = 0;
        let upstream5xx = 0;

        const keys = Object.keys(i.status);
        for (let k of keys) {
          if (startsWith(k, '2')) {
            cdn2xx += i.status[k]['hit_pv'];
            upstream2xx += i.status[k]['miss_pv'];
          } else if (startsWith(k, '3')) {
            cdn3xx += i.status[k]['hit_pv'];
            upstream3xx += i.status[k]['miss_pv'];
          } else if (startsWith(k, '4')) {
            cdn4xx += i.status[k]['hit_pv'];
            upstream4xx += i.status[k]['miss_pv'];
          } else if (startsWith(k, '5')) {
            cdn5xx += i.status[k]['hit_pv'];
            upstream5xx += i.status[k]['miss_pv'];
          }
        }

        seriesData.status['2xx'] = {hit_pv: cdn2xx, miss_pv: upstream2xx};
        seriesData.status['3xx'] = {hit_pv: cdn3xx, miss_pv: upstream3xx};
        seriesData.status['4xx'] = {hit_pv: cdn4xx, miss_pv: upstream4xx};
        seriesData.status['5xx'] = {hit_pv: cdn5xx, miss_pv: upstream5xx};

        return seriesData;
      });
    },
  },
  methods: {
    moment,
    async fetchGraphData() {
      this.fetchOverviewData();
      this.fetchHttpResponseData();
    },
    async fetchOverviewData() {
      this.loading = true;

      const params = {
        cname: this.userCname,
        start: format(this.timeRange[0].toDate(), 'yyyy-MM-dd HH:mm:ss'),
        end: format(this.timeRange[1].toDate(), 'yyyy-MM-dd HH:mm:ss'),
        interval: this.interval,
        interval_time_unit: this.interval_time_unit,
      };
      if (this.selectedDomain.pk !== allToken) {
        params.get_by_domain = this.selectedDomain.name;
      }

      let response;
      try {
        response = await axios({
          url: 'domain/traffic_stats/get_overview_data/',
          params: params,
        });
      } catch (errors) {
        console.error(errors);
      }

      const trafficDomainData = {};
      const groupByKeyDomain = 'domain';

      this.loading = false;

      this.intervals = response.data.intervals || [];

      if (this.userCname) {
        // Store shadow domains from the current API response
        const shadowDomains = response.data.domains.reduce((acc, item) => {
          if (item.shadow && item.shadow !== '') {
            const shadowList = item.shadow.split(','); // shadow can be comma separated instead of single
            shadowList.forEach(shadowDomain => acc[shadowDomain.trim()] = item.domain);
          }
          return acc;
        }, {});

        response.data.domains.forEach(item => {
          let domain = item.domain;

          // Check if the current domain is a shadow domain and find its parent domain
          const matchedExplicitShadow = shadowDomains[domain];
          const matchedWildcard = Object.keys(shadowDomains).find(wildcard => {
            const wildcardRegex = new RegExp(`^${wildcard.replace("*", ".*")}$`);
            return domain.match(wildcardRegex);
          });

          const parentDomain = matchedExplicitShadow || shadowDomains[matchedWildcard];

          if (parentDomain) {
            // Merge with the parent domain if it's a shadow domain
            if (!trafficDomainData[parentDomain]) {
              trafficDomainData[parentDomain] = [];
            }
            const parentItemIndex = trafficDomainData[parentDomain].findIndex(entry => entry.domain === parentDomain && entry.second === item.second);
            if (parentItemIndex !== -1) {
              trafficDomainData[parentDomain][parentItemIndex].total_traffic += item.total_traffic;
              trafficDomainData[parentDomain][parentItemIndex].total_request += item.total_request;
            } else {
              trafficDomainData[parentDomain].push({
                domain: parentDomain,
                shadow: matchedExplicitShadow || matchedWildcard,
                total_traffic: item.total_traffic,
                total_request: item.total_request,
                second: item.second
              });
            }
          } else {
            // Create new entry if it's not a shadow domain

            // In some cases, the API might not contain main domain traffic but only shadow domain traffic
            // So, we add it as it is, but if the domain starts with '_.', we remove it and replace it with the main domain
            domain = domain.replace(/^_./, '');

            if (!trafficDomainData[domain]) {
              trafficDomainData[domain] = [];
            }
            trafficDomainData[domain].push({
              domain,
              shadow: item.shadow,
              total_traffic: item.total_traffic,
              total_request: item.total_request,
              second: item.second
            });
          }
        });
        this.trafficDomainData = trafficDomainData;
      }  else {
        this.$refs.overviewDomain.$refs.tableTraffic.changeSort('total_traffic', 0);

      }
    },
    async fetchHttpResponseData() {
      this.loading = true;

      const params = {
        cname: this.userCname,
        start: format(this.timeRange[0].toDate(), 'yyyy-MM-dd HH:mm:ss'),
        end: format(this.timeRange[1].toDate(), 'yyyy-MM-dd HH:mm:ss'),
        interval: this.interval,
        interval_time_unit: this.interval_time_unit,
      };
      if (this.selectedDomain.pk !== allToken) {
        params.get_by_domain = this.selectedDomain.name;
      }

      let response;
      try {
        response = await axios({
          url: 'domain/traffic_stats/http_response_stats/',
          params: params,
        });
      } catch (errors) {
        console.error(errors);
      }

      this.loading = false;

      this.http_response_intervals = response.data.intervals || [];
    },
    adjustTimeRange(adjustment) {
      let startRange;
      let endRange = moment();

      // last 24 hours
      if (adjustment === TIME_ADJUSTMENTS.LAST_24_HOURS) {
        endRange = endRange.set('minute', 0).set('second', 0);
        startRange = moment(endRange)
            // adjust to the date
            .subtract(adjustment, 'minutes')
            // set to the hour of the adjusted date
            .set('minute', 0);

        this.setIntervalToHour();
      }
      // last 7 days
      else if (adjustment === TIME_ADJUSTMENTS.LAST_7_DAYS) {
        endRange = endRange.set('hour', 23).set('minute', 59).set('second', 59)
        startRange = moment(endRange)
            // adjust to the date
            .subtract(MINUTES_IN_DAY * 7, 'minutes')
            // set the time to the beginning of the adjusted date
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0);

        this.setIntervalToDay();
      }
      // last 30 days
      else if (adjustment === TIME_ADJUSTMENTS.LAST_30_DAYS) {
        endRange = endRange.set('hour', 23).set('minute', 59).set('second', 59)
        startRange = moment(endRange)
            // adjust to the date
            .subtract(MINUTES_IN_DAY * 30, 'minutes')
            // set the time to the beginning of the adjusted date
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0);

        this.setIntervalToDay();
      }

      this.timeRange = [startRange, endRange];
      this.fetchGraphData();
    },
    hideCustomRangePicker() {
      this.showCustomRangePicker = false;
    },
    handleDateRangePickerChange(newRange) {
      const start = moment(newRange[0]).set('hour', 0).set('minute', 0).set('second', 0);
      const end = moment(newRange[1]).set('hour', 23).set('minute', 59).set('second', 59);
      this.timeRange = [start, end];
      this.hideCustomRangePicker();

      this.setIntervalToDay();
      this.fetchGraphData();
    },
    handleIntervalUnitChange(timeUnit) {
      this.interval_time_unit = timeUnit;
      if (timeUnit === INTERVAL_TIME_UNITS.MINUTES) {
        this.interval = 15;
      } else {
        this.interval = 1;
      }

      this.fetchGraphData();
    },
    setIntervalToHour() {
      this.interval = 1;
      this.interval_time_unit = INTERVAL_TIME_UNITS.HOUR;
    },
    setIntervalToDay() {
      this.interval = 1;
      this.interval_time_unit = INTERVAL_TIME_UNITS.DAY;
    },
    handleDomainSelect() {
      this.fetchGraphData();
    },
    download() {
      const overviewReport = () => {
        const headerOrder = [
          'second',
          'cdn_requests',
          'cdn_traffic',
          'cache_hit_ratio',
          'upstream_requests',
          'upstream_traffic',
        ];
        const headers = [
          this.$t('DateTime'),
          this.$t('CDNRequests'),
          this.$t('CDNTraffic'),
          this.$t('CacheHitRatio'),
          this.$t('UpstreamRequests'),
          this.$t('UpstreamTraffic'),
        ];

        const ws = XLSX.utils.aoa_to_sheet([headers]);
        const dataFormatted = this.intervals.map((i) => {
          let second = i.second;
          if (this.interval_time_unit === 'd') {
            const parts = second.split(' ');

            second = parts[0];
          }

          return {
            second: second,
            cdn_requests: numeral(i.cdn_requests).format('0,0'),
            cdn_traffic: numeral(i.cdn_traffic).format('0.00 b'),
            cache_hit_ratio: numeral(i.cache_hit_ratio).format('0.000'),
            upstream_requests: numeral(i.upstream_requests).format('0,0'),
            upstream_traffic: numeral(i.upstream_traffic).format('0.00 b'),
          };
        });

        XLSX.utils.sheet_add_json(ws, dataFormatted, {header: headerOrder, skipHeader: true, origin: 'A2'});

        return ws;
      }

      const httpResponseStatus = () => {
        const headerOrder = [
          'second',
          'cdn_2xx',
          'cdn_3xx',
          'cdn_4xx',
          'cdn_5xx',
          'upstream_2xx',
          'upstream_3xx',
          'upstream_4xx',
          'upstream_5xx',
        ];
        const headers = [
          this.$t('DateTime'),
          this.$t('CDN0', ['2XX']),
          this.$t('CDN0', ['3XX']),
          this.$t('CDN0', ['4XX']),
          this.$t('CDN0', ['5XX']),
          this.$t('Upstream0', ['2XX']),
          this.$t('Upstream0', ['3XX']),
          this.$t('Upstream0', ['4XX']),
          this.$t('Upstream0', ['5XX']),
        ];

        const ws = XLSX.utils.aoa_to_sheet([headers]);
        const dataFormatted = this.httpResponseStatusSeriesInterval.map((i) => {
          let second = i.second;
          if (this.interval_time_unit === 'd') {
            const parts = second.split(' ');

            second = parts[0];
          }

          return {
            second: second,
            cdn_2xx: i.status['2xx'].hit_pv,
            cdn_3xx: i.status['3xx'].hit_pv,
            cdn_4xx: i.status['4xx'].hit_pv,
            cdn_5xx: i.status['5xx'].hit_pv,
            upstream_2xx: i.status['2xx'].miss_pv,
            upstream_3xx: i.status['3xx'].miss_pv,
            upstream_4xx: i.status['4xx'].miss_pv,
            upstream_5xx: i.status['5xx'].miss_pv,
          };
        });

        XLSX.utils.sheet_add_json(ws, dataFormatted, {header: headerOrder, skipHeader: true, origin: 'A2'});

        return ws;
      }

      const overviewWs = overviewReport();
      const httpResponseStatusWs = httpResponseStatus();

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, overviewWs, 'Overview report');
      XLSX.utils.book_append_sheet(wb, httpResponseStatusWs, 'HTTP response status');

      const domainFilename = this.selectedDomain.name.replace(/ /g, '-')
      XLSX.writeFile(wb, `traffic-report-${domainFilename}-${this.timeRangeDisplay.replace(/ /g, '')}.xlsx`);
    },
  },
}
</script>
