<template>
  <div class="bg-white p-3">
    <a-tabs default-active-key="overview_tab" :animated="false">
      <a-tab-pane key="overview_tab" :tab="$t('overview')">
        <OverviewTraffic :domainData="mainDomainData" @loadMoreDomain="loadMoreMainDomains"
                         :searchDomain="searchMainDomain"/>
      </a-tab-pane>
      <a-tab-pane key="threat_tab" :tab="$t('Threats')">
        <ThreatsDashboard/>
      </a-tab-pane>
      <a-tab-pane key="logs_tab" :tab="$t('logs')">
        <AccesslogTraffic :domainData="domainData" @loadMoreDomain="loadMoreDomains" :searchDomain="searchDomain"/>
      </a-tab-pane>
      <a-tab-pane key="AuditLogs _tab" :tab="$t('Audit logs')">
        <AuditlogTraffic :domainData="domainData" @loadMoreDomain="loadMoreDomains" :searchDomain="searchDomain"/>
      </a-tab-pane>
      <a-tab-pane key="attack-summary" :tab="$t('AttackSummary')" :forceRender="true">
        <AttackSummary/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js";
import {debounce, isEmpty} from 'lodash';

import OverviewTraffic from './charts/OverviewTraffic';
import AccesslogTraffic from './charts/AccesslogTraffic';
import ThreatsDashboard from '@/views/domain/charts/ThreatsDashboard';
import AuditlogTraffic from './charts/AuditlogTraffic';
import AttackSummary from './charts/AttackSummary';

const DOMAINS_PER_PAGE = 20;
const MILLISECONDS_DELAY = 500;

export default {
  components: {
    ThreatsDashboard,
    AccesslogTraffic,
    OverviewTraffic,
    AuditlogTraffic,
    AttackSummary,
  },
  data() {
    return {
      mainDomainData: {domains: [], hasNext: false, loading: false},
      domainData: {domains: [], hasNext: false, loading: false},
      domainsPage: 1,
      shadowDomainsPage: 1,
      storedDomainSearch: null,
      storedShadowDomainSearch: null,
      delayedFetchDomains: null,
      delayedFetchMainDomains: null,
    };
  },
  mounted() {
    this.delayedFetchDomains = debounce(this.fetchDomains, MILLISECONDS_DELAY);
    this.delayedFetchMainDomains = debounce(this.fetchMainDomains, MILLISECONDS_DELAY);

    this.fetchDomains({page: 1});
    this.fetchMainDomains({page: 1});
  },
  methods: {
    async fetchMainDomains(options) {
      const filterParams = {
        page: options.page,
        perpage: DOMAINS_PER_PAGE,
        main: true
      };
      if (options.search) {
        filterParams.search = options.search;
      }

      this.mainDomainData = {...this.mainDomainData, loading: true};

      const response = await axios
          .get('domain/user/domains/', {
            params: filterParams
          });

      const newDomainData = {...this.mainDomainData};
      if (options.page === 1) {
        newDomainData.domains = [];
      }
      let newData = [];
      let index = 1;
      response.data.domains.forEach(item => {
        newData.push({pk: index, name: item});
        index += 1;
      });
      newDomainData.domains = [...newDomainData.domains, ...newData];
      // newDomainData.hasNext = Boolean(response.data.next);
      newDomainData.loading = false;

      this.mainDomainData = newDomainData;
    },
    async fetchDomains(options) {
      const filterParams = {
        page: options.page,
        status: true
      };
      if (options.search) {
        filterParams.search = options.search;
      }

      this.domainData = {...this.domainData, loading: true};

      const response = await axios
          .get('domain/user/domains/', {
            params: filterParams
          });

      const newDomainData = {...this.domainData};
      if (options.page === 1) {
        newDomainData.domains = [];
      }
      let newData = [];
      let index = 1;
      response.data.domains.forEach(item => {
        newData.push({pk: index, name: item});
        index += 1;
      });
      newDomainData.domains = [...newDomainData.domains, ...newData];
      // newDomainData.hasNext = Boolean(response.data.next);
      newDomainData.loading = false;

      this.domainData = newDomainData;
    },
    async loadMoreDomains() {
      const options = {page: ++this.domainsPage};
      if (this.storedDomainSearch) {
        options.search = this.storedDomainSearch;
      }

      await this.fetchDomains(options);
    },
    async loadMoreMainDomains() {
      const options = {page: ++this.domainsPage};
      if (this.storedDomainSearch) {
        options.search = this.storedDomainSearch;
      }
      await this.fetchMainDomains(options);
    },
    async searchDomain(searchQuery) {
      this.domainsPage = 1;
      this.storedDomainSearch = isEmpty(searchQuery) ? null : searchQuery;

      // trigger loading even though the ajax is delayed to give immediate feedback to the user
      this.domainData = {...this.domainData, loading: true};

      this.delayedFetchDomains({page: this.domainsPage, search: this.storedDomainSearch});
    },
    async searchMainDomain(searchQuery) {
      this.domainsPage = 1;
      this.storedDomainSearch = isEmpty(searchQuery) ? null : searchQuery;

      // trigger loading even though the ajax is delayed to give immediate feedback to the user
      this.mainDomainData = {...this.mainDomainData, loading: true};

      this.delayedFetchMainDomains({page: this.domainsPage, search: this.storedDomainSearch});
    }
  }
};
</script>
