<template>
  <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
  />
</template>

<script>
import {Line as LineChartGenerator} from 'vue-chartjs/legacy';
import numeral from 'numeral';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    zoomPlugin,
);

const REQUESTS_Y_AXIS_ID = 'y';
const TRAFFIC_Y_AXIS_ID = 'y1';
const CACHE_HIT_RATIO_Y_AXIS_ID = 'y2';

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    intervals: {
      default: [],
    },
    intervalTimeUnit: {
      type: String,
      default: 'd',
    },
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    labels: function () {
      if (this.intervalTimeUnit === 'd') {
        return this.intervals.map((i) => {
          const parts = i.second.split(' ');

          return parts[0];
        });
      }

      let lastDate = null;
      const labels = [];

      for (let i of this.intervals) {
        let returnDate = false;
        const [datePart, timePart] = i.second.split(' ');
        if (lastDate !== datePart) {
          lastDate = datePart;
          returnDate = true;
        }

        if (returnDate)
          labels.push(i.second);
        else
          labels.push(timePart);
      }

      return labels;
    },
    cdnRequestsDataset: function () {
      return {
        label: this.$t('CDNRequests'),
        data: this.intervals.map((i) => i.cdn_requests),
        yAxisID: REQUESTS_Y_AXIS_ID,
        backgroundColor: '#ff0000',
        borderColor: '#ff0000',
      };
    },
    cdnTrafficDataset: function () {
      return {
        label: this.$t('CDNTraffic'),
        data: this.intervals.map((i) => i.cdn_traffic),
        yAxisID: TRAFFIC_Y_AXIS_ID,
        backgroundColor: '#0026CD',
        borderColor: '#0026CD',
      };
    },
    cacheHitRatioDataset: function () {
      return {
        label: this.$t('CacheHitRatio'),
        data: this.intervals.map((i) => i.cache_hit_ratio),
        yAxisID: CACHE_HIT_RATIO_Y_AXIS_ID,
        backgroundColor: '#CDD209',
        borderColor: '#CDD209',
      };
    },
    upstreamRequestsDataset: function () {
      return {
        label: this.$t('UpstreamRequests'),
        data: this.intervals.map((i) => i.upstream_requests),
        yAxisID: REQUESTS_Y_AXIS_ID,
        backgroundColor: '#1CD347',
        borderColor: '#1CD347',
      };
    },
    upstreamTrafficDataset: function () {
      return {
        label: this.$t('UpstreamTraffic'),
        data: this.intervals.map((i) => i.upstream_requests),
        yAxisID: TRAFFIC_Y_AXIS_ID,
        backgroundColor: '#CB00B8',
        borderColor: '#CB00B8',
      };
    },
    chartOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.$t('TrafficOverview')
          },
          legend: {
            position: 'bottom',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const isTrafficDataset = context.dataset.yAxisID === TRAFFIC_Y_AXIS_ID;

                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (isTrafficDataset) {
                  label += numeral(context.parsed.y).format('0.00 b');
                } else if (context.dataset.yAxisID === CACHE_HIT_RATIO_Y_AXIS_ID) {
                  label += numeral(context.parsed.y).format('0.000');
                } else {
                  label += context.parsed.y;
                }

                return label;
              }
            }
          },
          // zoom: {
          //   zoom: {
          //     wheel: {
          //       enabled: true,
          //     },
          //     pinch: {
          //       enabled: true
          //     },
          //     mode: 'xy',
          //   }
          // },
        },
        scales: {
          // CDN requests, Upstream requests
          [REQUESTS_Y_AXIS_ID]: {
            type: 'linear',
            // we always show the y-axis to make the alignment with the http status graph work
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: this.$t('traffic.Requests'),
            },
            ticks: {
              callback: function (value) {
                return numeral(value).format('0 a');
              }
            },
            afterFit(scale) {
              // we need this manual width so we could align with the http graph
              scale.width = 60;
            },
          },
          // CDN traffic, Upstream traffic
          [TRAFFIC_Y_AXIS_ID]: {
            type: 'linear',
            // we always show the y-axis to make the alignment with the http status graph work
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: this.$t('traffic.Traffic'),
            },
            ticks: {
              callback: function (value) {
                return numeral(value).format('0.00 b');
              }
            },
            afterFit(scale) {
              // we need this manual width so we could align with the http graph
              scale.width = 100;
            },
          },
          // cache hit ration
          [CACHE_HIT_RATIO_Y_AXIS_ID]: {
            type: 'linear',
            // we always show the y-axis to make the alignment with the http status graph work
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: this.$t('CacheHitRatio'),
            },
            afterFit(scale) {
              // we need this manual width so we could align with the http graph
              scale.width = 60;
            },
          },
        },
      };
    },
    chartData: function () {
      return {
        labels: this.labels,
        datasets: [
          this.cdnRequestsDataset,
          this.cdnTrafficDataset,
          this.cacheHitRatioDataset,
          this.upstreamRequestsDataset,
          this.upstreamTrafficDataset,
        ],
      };
    },
  },
  data() {
    return {}
  }
}
</script>
