<template>
  <div class="mt-3">
    <h6>{{ $t('TrafficOverview') }}</h6>
    <a-table :columns="columns" :data-source="tableData" rowKey="second" bordered :pagination="false"/>
  </div>
</template>
<script>
import numeral from 'numeral';

export default {
  props: ['intervals', 'intervalTimeUnit'],
  data() {
    return {
      columns: [
        {
          title: this.$t('DateTime'),
          dataIndex: 'second',
          key: 'second',
        },
        {
          title: this.$t('CDNRequests'),
          dataIndex: 'cdn_requests',
          key: 'cdn_requests',
        },
        {
          title: this.$t('CDNTraffic'),
          key: 'cdn_traffic',
          dataIndex: 'cdn_traffic',
        },
        {
          title: this.$t('CacheHitRatio'),
          key: 'cache_hit_ratio',
          dataIndex: 'cache_hit_ratio',
        },
        {
          title: this.$t('UpstreamRequests'),
          key: 'upstream_requests',
          dataIndex: 'upstream_requests',
        },
        {
          title: this.$t('UpstreamTraffic'),
          key: 'upstream_traffic',
          dataIndex: 'upstream_traffic',
        },
      ],
    };
  },
  computed: {
    tableData: function () {
      if (!this.intervals.length)
        return [];

      const intervals = [];
      let totalCdnRequests = 0;
      let totalCdnTraffic = 0;
      let totalUpstreamRequests = 0;
      let totalUpstreamTraffic = 0;

      for (let i of this.intervals) {
        let second = i.second;
        if (this.intervalTimeUnit === 'd') {
          const parts = second.split(' ');

          second = parts[0];
        }

        // for the total row we add last
        totalCdnRequests += i.cdn_requests;
        totalCdnTraffic += i.cdn_traffic;
        totalUpstreamRequests += i.upstream_requests;
        totalUpstreamTraffic += i.upstream_traffic;

        intervals.push({
          second: second,
          cdn_requests: numeral(i.cdn_requests).format('0,0'),
          cdn_traffic: numeral(i.cdn_traffic).format('0.00 b'),
          cache_hit_ratio: numeral(i.cache_hit_ratio).format('0.000'),
          upstream_requests: numeral(i.upstream_requests).format('0,0'),
          upstream_traffic: numeral(i.upstream_traffic).format('0.00 b'),
        });
      }

      intervals.push({
        second: this.$t('Total'),
        cdn_requests: numeral(totalCdnRequests).format('0,0'),
        cdn_traffic: numeral(totalCdnTraffic).format('0.00 b'),
        cache_hit_ratio: '-',
        upstream_requests: numeral(totalUpstreamRequests).format('0,0'),
        upstream_traffic: numeral(totalUpstreamTraffic).format('0.00 b'),
      });

      return intervals;
    },
  },
};
</script>