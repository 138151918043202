<template>
  <div>
    <a-timeline v-if="attacks.length">
      <a-timeline-item v-for="a in attacks" :key="a.datetime+a.content">[{{ a.datetime | moment('YYYY-MM-DD HH:mm:ss') }}] {{
          a.content
        }}
      </a-timeline-item>
    </a-timeline>
    <div v-else>
      <a-empty/>
    </div>
  </div>
</template>

<script>
import {getThreatsMitigationAttack} from '@/utilities/api';

export default {
  async mounted() {
    const response = await getThreatsMitigationAttack();

    this.attacks = response.data;
  },
  data() {
    return {
      attacks: [],
    }
  }
}
</script>
