<template>
  <div class="mt-3">
    <h6>{{ this.$t('HttpResponseStatus') }}</h6>
    <a-table :columns="columns" :data-source="tableData" rowKey="second" bordered/>
  </div>
</template>

<script>
export default {
  props: ['intervals', 'intervalTimeUnit'],
  data() {
    return {
      columns: [
        {
          title: this.$t('DateTime'),
          dataIndex: 'second',
          key: 'second',
        },
        {
          title: this.$t('CDN0', ['2XX']),
          dataIndex: 'cdn_2xx',
          key: 'cdn_2xx',
        },
        {
          title: this.$t('CDN0', ['3XX']),
          key: 'cdn_3xx',
          dataIndex: 'cdn_3xx',
        },
        {
          title: this.$t('CDN0', ['4XX']),
          key: 'cdn_4xx',
          dataIndex: 'cdn_4xx',
        },
        {
          title: this.$t('CDN0', ['5XX']),
          key: 'cdn_5xx',
          dataIndex: 'cdn_5xx',
        },
        {
          title: this.$t('Upstream0', ['2XX']),
          key: 'upstream_2xx',
          dataIndex: 'upstream_2xx',
        },
        {
          title: this.$t('Upstream0', ['3XX']),
          key: 'upstream_3xx',
          dataIndex: 'upstream_3xx',
        },
        {
          title: this.$t('Upstream0', ['4XX']),
          key: 'upstream_4xx',
          dataIndex: 'upstream_4xx',
        },
        {
          title: this.$t('Upstream0', ['5XX']),
          key: 'upstream_5xx',
          dataIndex: 'upstream_t5xx',
        },
      ],
    };
  },
  computed: {
    tableData: function () {
      return this.intervals.map((i) => {
        let second = i.second;
        if (this.intervalTimeUnit === 'd') {
          const parts = second.split(' ');

          second = parts[0];
        }

        return {
          second: second,
          cdn_2xx: i.status['2xx'].hit_pv,
          cdn_3xx: i.status['3xx'].hit_pv,
          cdn_4xx: i.status['4xx'].hit_pv,
          cdn_5xx: i.status['5xx'].hit_pv,
          upstream_2xx: i.status['2xx'].miss_pv,
          upstream_3xx: i.status['3xx'].miss_pv,
          upstream_4xx: i.status['4xx'].miss_pv,
          upstream_5xx: i.status['5xx'].miss_pv,
        }
      });
    },
  },
};
</script>