<template>
  <div>
    <Pie v-if="data.labels.length" :chart-data="data" :chart-options="options"/>
    <a-empty v-else/>
  </div>
</template>

<script>
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Pie} from 'vue-chartjs/legacy';
import {getThreatsResponseStatus} from '@/utilities/api';

ChartJS.register(ArcElement, Tooltip, Legend);

const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false
}

const DATA_COLORS = {
  '200': '#4ade80',
  '201': '#22c55e',
  '202': '#16a34a',
  '203': '#15803d',
  '204': '#166534',
  '205': '#14532d',
  '206': '#f0fdf4',
  '207': '#dcfce7',
  '208': '#bbf7d0',
  '226': '#86efac',
  '300': '#a5f3fc',
  '301': '#67e8f9',
  '302': '#22d3ee',
  '303': '#06b6d4',
  '304': '#0891b2',
  '307': '#0e7490',
  '308': '#155e75',
  '400': '#ede9fe',
  '401': '#ddd6fe',
  '402': '#c4b5fd',
  '403': '#a78bfa',
  '404': '#8b5cf6',
  '405': '#7c3aed',
  '406': '#6d28d9',
  '407': '#5b21b6',
  '408': '#4c1d95',
  '409': '#f3e8ff',
  '410': '#e9d5ff',
  '411': '#d8b4fe',
  '412': '#c084fc',
  '413': '#a855f7',
  '414': '#9333ea',
  '415': '#7e22ce',
  '416': '#6b21a8',
  '417': '#581c87',
  '418': '#f5d0fe',
  '425': '#f0abfc',
  '426': '#e879f9',
  '429': '#d946ef',
  '500': '#f472b6',
  '501': '#ec4899',
  '502': '#db2777',
  '503': '#be185d',
  '504': '#9d174d',
  '505': '#831843',
  '506': '#f43f5e',
  '507': '#e11d48',
  '508': '#be123c',
  '510': '#9f1239',
  '511': '#881337',
};

export default {
  components: {
    Pie,
  },
  async mounted() {
    const response = await getThreatsResponseStatus();
    
    const backgroundColor = [];
    const labels = [];
    const data =  [];
    for (let d of response.data) {
      labels.push(d.status);
      data.push(d.pv);
      backgroundColor.push(DATA_COLORS[d.status]);
    }

    this.data.labels = labels;
    this.data.datasets = [{ backgroundColor, data }];
  },
  data() {
    return {
      data: {
        labels: [],
        datasets: []
      },
      options: CHART_OPTIONS
    }
  },
}
</script>