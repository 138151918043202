<template>
  <div class="px-1 py-3">
    <div class="d-flex mt-3">
      <div class="w-50">
        <h3 class="font-small font-weight-normal">
          {{ $t('Range') }}:
          <span>{{ timeRangeDisplay }}</span>
        </h3>
        <div>
          <a-button @click="adjustTimeRange(adjustments.LAST_24_HOURS)" class="mr-2 mt-1">{{
              $t('time_picker.Last 24 hours')
            }}
          </a-button>
          <a-button @click="adjustTimeRange(adjustments.LAST_7_DAYS)" class="mr-2 mt-1">{{
              $t('time_picker.Last 7 days')
            }}
          </a-button>
          <a-button @click="adjustTimeRange(adjustments.LAST_30_DAYS)" class="mr-2 mt-1">{{
              $t('time_picker.Last 30 days')
            }}
          </a-button>
          <a-button @click="showCustomRangePicker = true" class="mr-2 mt-1">{{ $t('Custom') }}</a-button>
        </div>
      </div>
      <div class="w-50">
        <h3 class="font-small font-weight-normal">{{ $t('message.ActiveFiltersAndExcludes') }}</h3>
        <div v-if="hasActiveFiltersExcludes">
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleFilterIp(ip)"
                 v-for="ip in filters.filters.ip" :key="ip">{{ ip }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleFilterHost(host)"
                 v-for="host in filters.filters.host" :key="host">{{ host }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleFilterPath(path)"
                 v-for="path in filters.filters.path" :key="path">{{ path }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleFilterStatus(http_status)"
                 v-for="http_status in filters.filters.http_status" :key="http_status">{{ http_status }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleFilterCountry(country)"
                 v-for="country in filters.filters.country" :key="country">{{ countryName(country) }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleFilterProtocol(protocol)"
                 v-for="protocol in filters.filters.protocol" :key="protocol">{{ protocol }}
          </a-tag>

          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleExcludeIp(ip)" color="red"
                 v-for="ip in filters.excludes.ip" :key="ip">{{ ip }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleExcludeHost(host)"
                 color="red"
                 v-for="host in filters.excludes.host" :key="host">{{ host }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleExcludePath(path)"
                 color="red"
                 v-for="path in filters.excludes.path" :key="path">{{ path }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true"
                 @close="handleExcludeStatus(http_status)" color="red"
                 v-for="http_status in filters.excludes.http_status" :key="http_status">{{ http_status }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleExcludeCountry(country)"
                 color="red"
                 v-for="country in filters.excludes.country" :key="country">{{ countryName(country) }}
          </a-tag>
          <a-tag class="mb-1 d-inline-flex align-items-center" :closable="true" @close="handleExcludeProtocol(protocol)"
                 color="red"
                 v-for="protocol in filters.excludes.protocol" :key="protocol">{{ protocol }}
          </a-tag>
        </div>
        <div v-else>
          <p class="font-small text-muted">{{ $t('message.NoActiveFiltersAndExcludesSet') }}</p>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="w-100">&nbsp;</div>
      <div class="d-flex">
        <a-button @click="download"
                  :disabled="isDownloadDisabled"
                  icon="download" class="mr-2 mt-1">{{ $t('DownloadCSV') }}
        </a-button>

        <a-button @click="fetchData" :loading="loading" icon="reload" class="mt-1">
          {{ $t('Refresh') }}
        </a-button>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-lg-4 mb-2">
        <a-card class="h-100">
          <h6>{{ $t('SourceIPs') }}</h6>

          <AttackSummaryWidget :source="ipsList"
                               :loading="loading"
                               :selection-store="filters"
                               value-key="ip"
                               @filter="handleFilterIp"
                               @exclude="handleExcludeIp"/>
        </a-card>
      </div>

      <div class="col-12 col-lg-4 mb-2">
        <a-card class="h-100">
          <h6>{{ $t('Countries') }}</h6>

          <AttackSummaryWidget :source="countriesList"
                               :loading="loading"
                               :selection-store="filters"
                               value-key="country"
                               display-key="country_name"
                               @filter="handleFilterCountry"
                               @exclude="handleExcludeCountry"/>


        </a-card>
      </div>

      <div class="col-12 col-lg-4 mb-2">
        <a-card class="h-100">
          <h6>{{ $t('Hosts') }}</h6>

          <AttackSummaryWidget :source="hostsList"
                               :loading="loading"
                               :selection-store="filters"
                               value-key="host"
                               @filter="handleFilterHost"
                               @exclude="handleExcludeHost"/>
        </a-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 mb-2">
        <a-card class="h-100">
          <h6>{{ $t('Paths') }}</h6>

          <AttackSummaryWidget :source="pathsList"
                               :loading="loading"
                               :selection-store="filters"
                               value-key="path"
                               @filter="handleFilterPath"
                               @exclude="handleExcludePath"/>
        </a-card>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-2">
        <a-card class="h-100">
          <h6>{{ $t('HTTPStatus') }}</h6>

          <AttackSummaryWidget :source="statusList"
                               :loading="loading"
                               :selection-store="filters"
                               value-key="http_status"
                               @filter="handleFilterStatus"
                               @exclude="handleExcludeStatus"/>
        </a-card>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-2">
        <a-card class="h-100">
          <h6>{{ $t('domain.Protocols') }}</h6>

          <AttackSummaryWidget :source="protocolsList"
                               :loading="loading"
                               :selection-store="filters"
                               value-key="protocol"
                               @filter="handleFilterProtocol"
                               @exclude="handleExcludeProtocol"/>
        </a-card>
      </div>
    </div>

    <div class="d-flex flex-column">
      <a-card>
        <AttackSummaryMitigatedLine :intervals="sourceMitigatedData" :loading="loading"/>
      </a-card>
    </div>

    <DateRangePicker :show="showCustomRangePicker" @change="handleDateRangePickerChange"
                     @close="hideCustomRangePicker"/>
  </div>
</template>

<script>
import {format} from 'date-fns';
import moment from 'moment';
import XLSX from 'xlsx';
import {isEmpty} from 'lodash';

import axios from '@/plugins/axios';
import DateRangePicker from '@/views/domain/charts/DateRangePicker.vue';
import AttackSummaryWidget from '@/views/domain/charts/AttackSummaryWidget.vue';
import countriesJson from '@/views/domain/CountrySelector/countries.json';
import AttackSummaryMitigatedLine from '@/views/domain/charts/AttackSummaryMitigatedLine.vue';


const MINUTES_IN_DAY = 1440;

const TIME_ADJUSTMENTS = {
  LAST_24_HOURS: MINUTES_IN_DAY,
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_30_DAYS: 'LAST_30_DAYS',
  CUSTOM: 'CUSTOM',
};

export default {
  components: {
    DateRangePicker,
    AttackSummaryWidget,
    AttackSummaryMitigatedLine,
  },
  mounted() {
    setTimeout(() => {
      this.adjustTimeRange(TIME_ADJUSTMENTS.LAST_24_HOURS);
    }, 1000);
  },
  props: [],
  data() {
    return {
      timeRange: [moment(), moment()],
      showCustomRangePicker: false,
      sourceWidgetData: {},
      sourceMitigatedData: [],
      loading: false,
      filters: {
        filters: {
          ip: [],
          host: [],
          path: [],
          http_status: [],
          country: [],
          protocol: [],
        },
        excludes: {
          ip: [],
          host: [],
          path: [],
          http_status: [],
          country: [],
          protocol: [],
        },
      },
    };
  },
  computed: {
    userCname: function () {
      return this.$store.getters.cname;
    },
    timeRangeDisplay: function () {
      return format(this.timeRange[0].toDate(), 'yyyy-MM-dd') + ' ~ ' + format(this.timeRange[1].toDate(), 'yyyy-MM-dd');
    },
    adjustments: function () {
      return TIME_ADJUSTMENTS;
    },
    ipsList: function () {
      return this.sourceWidgetData.ips || [];
    },
    hostsList: function () {
      return this.sourceWidgetData.hosts || [];
    },
    pathsList: function () {
      return this.sourceWidgetData.paths || [];
    },
    statusList: function () {
      return this.sourceWidgetData.status || [];
    },
    countriesList: function () {
      const source = this.sourceWidgetData.countries || [];
      
      return source.map((m) => {
        return {...m, country_name: this.countryMap[m.country]};
      });
    },
    protocolsList: function () {
      return this.sourceWidgetData.protocols || [];
    },
    hasActiveFiltersExcludes: function () {
      for (const k in this.filters.filters) {
        if (this.filters.filters[k].length) {
          return true;
        }

        if (this.filters.excludes[k].length) {
          return true;
        }
      }

      return false;
    },
    countryMap: function () {
      const map = {};

      for (const c of countriesJson) {
        map[c.code] = c.name;
      }

      return map;
    },
    interval: function () {
      const [start, end] = this.timeRange;

      const daysDiff = end.diff(start, 'days');

      if (daysDiff < 3) {
        return '15m';
      } else if (daysDiff <= 10) {
        return '1h';
      }

      return '1d';
    },
    isDownloadDisabled: function () {
      if (this.loading) {
        return true;
      }

      return isEmpty(this.sourceWidgetData) && isEmpty(this.sourceMitigatedData);
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;

      await this.fetchWidgetData();
      await this.fetchMitigatedData();

      this.loading = false;
    },
    async fetchWidgetData() {
      const params = {
        cname: this.userCname,
        start: format(this.timeRange[0].toDate(), 'yyyy-MM-dd HH:mm:ss'),
        end: format(this.timeRange[1].toDate(), 'yyyy-MM-dd HH:mm:ss'),
        filters: this.filters,
      };

      let response;
      try {
        response = await axios({
          url: 'domain/attack-summary/widget-data/',
          params: params,
        });
      } catch (errors) {
        console.error(errors);
      }

      this.sourceWidgetData = response.data || {};
    },
    async fetchMitigatedData() {
      const params = {
        cname: this.userCname,
        start: format(this.timeRange[0].toDate(), 'yyyy-MM-dd HH:mm:ss'),
        end: format(this.timeRange[1].toDate(), 'yyyy-MM-dd HH:mm:ss'),
        filters: this.filters,
        interval: this.interval,
      };

      let response;
      try {
        response = await axios({
          url: 'domain/attack-summary/mitigated-data/',
          params: params,
        });
      } catch (errors) {
        console.error(errors);
      }

      this.sourceMitigatedData = response.data || {};
    },
    adjustTimeRange(adjustment) {
      let startRange;
      let endRange = moment();

      // last 24 hours
      if (adjustment === TIME_ADJUSTMENTS.LAST_24_HOURS) {
        endRange = endRange.set('minute', 0).set('second', 0);
        startRange = moment(endRange)
            // adjust to the date
            .subtract(adjustment, 'minutes')
            // set to the hour of the adjusted date
            .set('minute', 0);
      }
      // last 7 days
      else if (adjustment === TIME_ADJUSTMENTS.LAST_7_DAYS) {
        endRange = endRange.set('hour', 23).set('minute', 59).set('second', 59)
        startRange = moment(endRange)
            // adjust to the date
            .subtract(MINUTES_IN_DAY * 7, 'minutes')
            // set the time to the beginning of the adjusted date
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0);
      }
      // last 30 days
      else if (adjustment === TIME_ADJUSTMENTS.LAST_30_DAYS) {
        endRange = endRange.set('hour', 23).set('minute', 59).set('second', 59)
        startRange = moment(endRange)
            // adjust to the date
            .subtract(MINUTES_IN_DAY * 30, 'minutes')
            // set the time to the beginning of the adjusted date
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0);
      }

      this.timeRange = [startRange, endRange];
      this.fetchData();
    },
    handleRangeChange(newVal) {
      this.timeRangePickerValue = newVal;
    },
    hideCustomRangePicker() {
      this.showCustomRangePicker = false;
    },
    handleDateRangePickerChange(newRange) {
      const start = moment(newRange[0]).set('hour', 0).set('minute', 0).set('second', 0);
      const end = moment(newRange[1]).set('hour', 23).set('minute', 59).set('second', 59);
      this.timeRange = [start, end];
      this.hideCustomRangePicker();
      this.fetchData();
    },
    addFilter(key, value) {
      if (this.filters.filters[key].includes(value)) {
        this.filters.filters[key] = this.filters.filters[key].filter((v) => v !== value);
      } else {
        this.filters.filters[key].push(value);
      }
    },
    addExclude(key, value) {
      if (this.filters.excludes[key].includes(value)) {
        this.filters.excludes[key] = this.filters.excludes[key].filter((v) => v !== value);
      } else {
        this.filters.excludes[key].push(value);
      }
    },
    handleFilterIp(value) {
      this.addFilter('ip', value);
      this.fetchData();
    },
    handleExcludeIp(value) {
      this.addExclude('ip', value);
      this.fetchData();
    },
    handleFilterStatus(value) {
      this.addFilter('http_status', value);
      this.fetchData();
    },
    handleExcludeStatus(value) {
      this.addExclude('http_status', value);
      this.fetchData();
    },
    handleFilterHost(value) {
      this.addFilter('host', value);
      this.fetchData();
    },
    handleExcludeHost(value) {
      this.addExclude('host', value);
      this.fetchData();
    },
    handleFilterPath(value) {
      this.addFilter('path', value);
      this.fetchData();
    },
    handleExcludePath(value) {
      this.addExclude('path', value);
      this.fetchData();
    },
    handleFilterCountry(value) {
      this.addFilter('country', value);
      this.fetchData();
    },
    handleExcludeCountry(value) {
      this.addExclude('country', value);
      this.fetchData();
    },
    handleFilterProtocol(value) {
      this.addFilter('protocol', value);
      this.fetchData();
    },
    handleExcludeProtocol(value) {
      this.addExclude('protocol', value);
      this.fetchData();
    },
    countryName(country) {
      return this.countryMap[country] || country;
    },
    download() {
      const parametersReport = () => {
        function formatData(source, parameterTitle, parameterKey, store) {
          for (let i = 0; i < source.length; i++) {
            let parameter = '';
            if (i === 0) {
              parameter = parameterTitle;
            }

            const item = source[i];

            store.push({
              parameter,
              value: item[parameterKey],
              hits: item.hits,
            });
          }
        }

        const headerOrder = [
          'parameter',
          'value',
          'hits',
        ];
        const headers = [
          this.$t('domain.parameter'),
          this.$t('Value'),
          this.$t('Hits'),
        ];

        const ws = XLSX.utils.aoa_to_sheet([headers]);
        const dataFormatted = [];

        formatData(this.ipsList, this.$t('SourceIPs'), 'ip', dataFormatted);
        formatData(this.countriesList, this.$t('Countries'), 'country_name', dataFormatted);
        formatData(this.hostsList, this.$t('Hosts'), 'host', dataFormatted);
        formatData(this.pathsList, this.$t('Paths'), 'path', dataFormatted);
        formatData(this.statusList, this.$t('HTTPStatus'), 'http_status', dataFormatted);
        formatData(this.protocolsList, this.$t('domain.Protocols'), 'protocol', dataFormatted);

        XLSX.utils.sheet_add_json(ws, dataFormatted, {header: headerOrder, skipHeader: true, origin: 'A2'});

        return ws;
      }
      const mitigatedReport = () => {
        const headerOrder = [
          'second',
          'mitigated',
          'not_mitigated',
        ];

        const headers = [
          this.$t('DateTime'),
          this.$t('Mitigated'),
          this.$t('NotMitigated'),
        ];

        const ws = XLSX.utils.aoa_to_sheet([headers]);
        const dataFormatted = this.sourceMitigatedData.map((i) => {
          return {
            second: i.second,
            mitigated: i.mitigated,
            not_mitigated: i.not_mitigated,
          };
        });

        XLSX.utils.sheet_add_json(ws, dataFormatted, {header: headerOrder, skipHeader: true, origin: 'A2'});

        return ws;
      };

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, parametersReport(), this.$t('domain.Parameters'));
      XLSX.utils.book_append_sheet(wb, mitigatedReport(), this.$t('MitigatedVsNotMitigated'));

      XLSX.writeFile(wb, `attack-summary-${this.timeRangeDisplay.replace(/ /g, '')}.xlsx`);
    },
  },
}
</script>