<template>
  <div class="top-container">
    <div class="filter-controls row">
      <div class="col-md-6 col-sm-12">
        <h5>{{ $t('Domain') }}</h5>
        <p>{{ $t('message.select_domain_access_logs_help') }}</p>

        <div class="radio-buttons-wrapper">
          <label>
            <input type="radio" value="cname" v-model="selectedDropdownValue" @change="handleRadioChange">
            {{ $t('Cname') }}
          </label>
          <label>
            <input type="radio" value="domain" v-model="selectedDropdownValue" @change="handleRadioChange">
            {{ $t('Domain') }}
          </label>
        </div>

        <div v-if="selectedDropdownValue === 'domain'" class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('Domain') }}</label>
          <div class="col-sm-8">
            <multiselect
                         id="multiselect_domain"
                         v-model="selectedDomains"
                         :options="domainsOptions"
                         label="name"
                         track-by="pk"
                         :loading="domainData.loading"
                         :multiple="true"
                         :close-on-select="false"
                         @search-change="searchDomain"
                         @input="multiselectClickHandler">
              <template slot="afterList">
                <span class="load-more" @click="$emit('loadMoreDomain')" v-if="domainData.hasNext">{{
                    $t('load_more')
                  }}</span>
              </template>
              <span slot="noResult">{{ $t('message.domain_not_found') }}</span>
            </multiselect>
          </div>
        </div> 
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('Search:') }}</label>
          <div class="col-sm-8">
            <div class="date-picker-container option-item">
              <slideout-panel></slideout-panel>
              <div :class="{ 'time-range-display': true, 'loading': isLoading }">
                <div class="time-range-control" @click="showPanel">
                  <!-- <CIcon name="cil-clock" style="margin-right: 6px;"/> -->
                  <div class="text-display">
                    <span class="time-range">{{ timeRangeDisplay }}</span>
                  </div>
                </div>
              </div>
            </div>
       </div>
       </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <h5>{{ $t('Filter') }}</h5>
        <p>{{ $t('message.filter_by_access_logs_help') }}</p>

        <CInput
            id="filter_ip_address"
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-6'}"
            :label="$t('Remote_Address')"
            :placeholder="$t('helps.ip_address_placeholder')"
            v-model="filterIpAddress"
        ></CInput>

        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('status') }}</label>
          <div class="col-sm-6">
            <multiselect
                         id="filter_http_staus_response"
                         v-model="filterHttpStatusResponse"
                         :options="statusResponseOptions"
                         track-by="value"
                         label="name"
                         :close-on-select="true"></multiselect>
          </div>
        </div>

        <div class="form-group form-row" v-if="filterHttpStatusResponse.value === 'CUSTOM'">
          <label class="col-form-label col-sm-3">{{ $t('status_code') }}</label>
          <div class="col-sm-6">
            <multiselect
                         id="select_custom_status_code"
                         v-model="selectCustomStatusCodes"
                         :options="httpStatusCodeOptions"
                         :multiple="true"
                         :close-on-select="false"
                         group-values="codes"
                         group-label="group"
                         :group-select="true"
                         track-by="value"
                         label="name"></multiselect>
          </div>
        </div>
        
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('traffic.RequestTime') }}</label>
          <div class="col-sm-6">
            <OperatorValueField
                selectOperatorId="select_request_time"
                inputOperatorId="input_request_time"
                @change="handleRequestTimeUpdate" />
          </div>
        </div>
        
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('traffic.BodyBytesSent') }}</label>
          <div class="col-sm-6">
            <OperatorValueField
                selectOperatorId="select_body_time"
                inputOperatorId="input_body_time"
                @change="handleBodyBytesSentUpdate" />
          </div>
        </div>
        
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('CacheStatus') }}</label>
          <div class="col-sm-6">
            <multiselect
                         id="select_filter_cache"
                         v-model="filterCache"
                         :options="['-', 'HIT', 'BYPASS', 'MISS']"
                         :close-on-select="true"></multiselect>
          </div>
        </div>

      </div>
    </div>

    <div class="button-container form-row">
      <CButton
          id="download_logs"
          color="secondary"
          :disabled="isLoading"
          :aria-disabled="isLoading"
          @click="handleDownloadLogsClicked">
        <CIcon name="cil-cloud-download" />
        {{ $t('DownloadLogs') }}
      </CButton>
      <CButton
          id="filter_logs"
          color="primary"
          :disabled="isLoading"
          :aria-disabled="isLoading"
          @click="handleFilterClicked">
        <CIcon name="cil-filter" />
        {{ $t('Filter') }}
      </CButton>
       <a-tag v-if="count" class="count" color="blue">{{ $t('SearchCount') }} {{ searchCount }}
      </a-tag>
    </div>

    <CDataTable
        :items="accesslogData"
        :loading="isLoading"
    >
    </CDataTable>
    <CPagination v-if="totalPages > 0" :activePage.sync="page" :pages="totalPages" @update:activePage="changePage" align="center"/>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.top-container {
  margin-top: 6px;
  padding: 25px;
}
.radio-buttons-wrapper {
  display: flex;
  margin-bottom: 10px;
}
.radio-buttons-wrapper label {
  margin-right: 10px;
}

.filter-controls .multiselect__content .load-more {
  font-size: 0.9em;
  margin-left: 12px;
  color: #39f;
  cursor: pointer;
}

.filter-controls .multiselect__content .load-more:hover {
  text-decoration: underline;
}

.button-container {
  margin-left: 20px;
  margin-bottom: 20px;
}

.button-container button:first-child {
  margin-right: 10px;
}

.date-picker-container {
  margin-top: 6px;
}
.count{
  margin-left: 750px;
  display: flex;
  align-items: center;
}
.btn_search{
  width: auto;
  height: 20px !important;
  margin-top: 11px;
  border-color:rgb(8, 50, 141);
  background-color:white;
  color:rgb(8, 50, 141);
}
.top-overview-container .multiselect__content .load-more {
  font-size: 0.9em;
  margin-left: 12px;
  color: #39f;
  cursor: pointer;
}
.top-overview-container .multiselect__content .load-more:hover {
  text-decoration: underline;
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 10px;
}
.opacity-0 {
  opacity: 0;
}
.spinner-container span {
  margin-left: 10px;
}
.time-range-label {
  margin-bottom: 5px;
}
.time-range-display.loading .time-range-control {
  color: gray;
  background-color: #fbfbfb;
  
}
.time-range-display.loading .time-range-control {
  cursor: not-allowed;
}
.time-range-display .time-range-control {
  display: inline-flex;
  align-items: center;
  color: #321fdb;
  cursor: pointer;
  font-weight: 500;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
}
.time-range-display .time-range-control:hover {
  background-color: #fbfbfb;
}
.text-display {
  display: flex;
  flex-direction: column;
}
.time-range-display .time-range {
  font-size: 0.9em;
  font-weight: normal;
  color: gray;
}
.option__title.all span {
  display: block;
}
.option__title.all span:first-child {
  font-weight: 500;
}
.option__title.all .description {
  font-size: 0.9em;
  margin-top: 5px;
}
.select-cname {
  display: inline-block;
}
</style>

<script>
import Multiselect from 'vue-multiselect';
import {format, subDays} from 'date-fns';
import {isEmpty} from 'lodash';

import axios from "@/plugins/axios.js";
import OperatorValueField, {VALUE_DEFAULT} from '@/views/domain/charts/OperatorValueField';
import {EventBus, Events} from "@/plugins/event-bus.js";


const STATUS_CODES = [
  {
    group: '1xx - Informational',
    codes: [
      {name: '100 - Continue', value: 100},
      {name: '101 - Switching Protocols', value: 101}
    ]
  },
  {
    group: '2xx - Successful',
    codes: [
      {name: '200 - OK', value: 200},
      {name: '201 - Created', value: 201},
      {name: '202 - Accepted', value: 202},
      {name: '203 - Non-Authoritative Information', value: 203},
      {name: '204 - No Content', value: 204},
      {name: '205 - Reset Content', value: 205},
      {name: '206 - Partial Content', value: 206}
    ]
  },
  {
    group: '3xx - Redirection',
    codes: [
      {name: '300 - Multiple Choices', value: 300},
      {name: '301 - Moved Permanently', value: 301},
      {name: '302 - Found', value: 302},
      {name: '303 - See Other', value: 303},
      {name: '304 - Not Modified', value: 304},
      {name: '305 - Use Proxy', value: 305},
      {name: '306 - Switch Proxy', value: 306},
      {name: '307 - Temporary Redirect', value: 307}
    ]
  },
  {
    group: '4xx - Client Error',
    codes: [
      {name: '400 - Bad Request', value: 400},
      {name: '401 - Unauthorized', value: 401},
      {name: '402 - Payment Required', value: 402},
      {name: '403 - Forbidden', value: 403},
      {name: '404 - Not Found', value: 404},
      {name: '405 - Method Not Allowed', value: 405},
      {name: '406 - Not Acceptable', value: 406},
      {name: '407 - Proxy Authentication Required', value: 407},
      {name: '408 - Request Timeout', value: 408},
      {name: '409 - Conflict', value: 409},
      {name: '410 - Gone', value: 410},
      {name: '411 - Length Required', value: 411},
      {name: '412 - Precondition Failed', value: 412},
      {name: '413 - Payload Too Large', value: 413},
      {name: '414 - URI Too Long', value: 414},
      {name: '415 - Unsupported Media Type', value: 415},
      {name: '416 - Range Not Satisfiable', value: 416},
      {name: '417 - Expectation Failed', value: 417},
      {name: '418 - I\'m a teapot', value: 418},
      {name: '425 - Request is too early', value: 425},
      {name: '426 - Upgrade Required', value: 426},
      {name: '499 - Client Closed Request', value: 499}
    ]
  },
  {
    group: '5xx - Server Error',
    codes: [
      {name: '500 - Internal Server Error', value: 500},
      {name: '501 - Not Implemented', value: 501},
      {name: '502 - Bad Gateway', value: 502},
      {name: '503 - Service Unavailable', value: 503},
      {name: '504 - Gateway Time-out', value: 504},
      {name: '505 - HTTP Version Not Supported', value: 505},
      {name: '584 - Identity_insert Is Set To Off', value: 584}
    ]
  }
];

export default {
  components: {OperatorValueField, Multiselect},
  props: {
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    domainData: Object,
    shadowData: Object,
    searchDomain: Function,
    searchShadowDomain: Function,
  },
  mounted() {
    this.$store.commit('resetTimeRangePicker');
    this.fetchData(true);
    this.scroll();
    this.selectedDropdownValue = 'cname';
  },
  computed: {
    domainsOptions: function () {
      return Object.freeze(this.domainData.domains)
    },
    beforeDestroy() {
    EventBus.$off(Events.DOWNLOAD_CERTIFICATE);
  },
  timeRange: {
      get() {
        return this.$store.state.timeRangePicker.range;
      },
      set(value) {
        this.$store.state.timeRangePicker.range = value;
      }
    },
    timeRangeDisplay: function () {
      const timeFormat = 'MMM d, yyyy';
      return format(this.timeRange.start, timeFormat) + '→' + format(this.timeRange.end, timeFormat);
    },
  },
  data() {
    return {
      accesslogData: [],
      selectedDomains: [],
      viewDate: new Date(),
      totalAccessLogData: [],
      from_time:[],
      to_time:[],
      minDate: subDays(new Date(), 30),
      isLoading: false,
      filterLimit: 100,
      page: 1,
      totalPages:0,
      filterIpAddress: '',
      filterHttpStatusResponse: {
        name: '-',
        value: '-'
      },
      filterCache: '-',
      statusResponseOptions: [
        {
          name: '-',
          value: '-'
        },
        {
          name: 'NORMAL (2xx, 3xx)',
          value: 'NORMAL'
        },
        {
          name: 'ERROR (4xx, 5xx)',
          value: 'ERROR'
        },
        {
          name: 'CUSTOM',
          value: 'CUSTOM'
        }
      ],
      selectCustomStatusCodes: [],
      httpStatusCodeOptions: STATUS_CODES,
      requestTimeOperatorValue: VALUE_DEFAULT,
      bodyBytesSentOperatorValue: VALUE_DEFAULT,
      searchCount: 0,
      count:0,
      selectedDropdownValue:'',
      selectedDropdownValue: 'cname',
    };
  },
 
  methods: {
    multiselectClickHandler() {
     const selectedDomains = this.selectedDomains.map((domain) => domain.name);
     const domainsTotalCharacters = selectedDomains.reduce((total, domain) => total + domain.length, 0);
     if (domainsTotalCharacters >= 1800) {
       this.$notification.warning({ message: this.$t('message.DomainCharacterLimitError') });
       this.selectedDomains.pop();
     }},
    handleRadioChange() {
      if (this.selectedDropdownValue) {
        if(this.selectedDropdownValue === "domain"){
          return this.selectedDropdownValue
        }
      }
    },
    showPanel() {
      if (this.isLoading) {
        return;
      }
      if (this.timeRangePanel) {
        this.timeRangePanel.show();
        return;
      }
      const handleApply = async () => {
        if (this.timeRangePanel) {
          await this.timeRangePanel.hide();
        }
        await this.fetchData(true);
      };
      this.timeRangePanel = this.$showPanel({
        component: 'app-time-range-picker',
        openOn: 'left',
        cssClass: 'timerange-slideout',
        width: 400,
        keepAlive: true,
        props: {
          onApply: handleApply,
          limited: false
        }
      });
    },
    async fetchData(type) {
      this.isLoading = true;
      try {
        const fromTime = format(this.timeRange.start, 'yyyy-MM-dd HH:mm:ss');
        const toTime = format(this.timeRange.end, 'yyyy-MM-dd HH:mm:ss');
        const selectedDomains = this.selectedDomains.map((domain) => domain.name);
        const offset = (this.page - 1) * this.filterLimit;
        const params = {
          page: type === "pagination" ? this.page : 1,
          offset:type === "pagination" ? offset : 0,
        }
        let commonData = this.getCommonData();
        commonData["from_time"] = fromTime;
        commonData["to_time"] = toTime;
        commonData["domains"] = this.selectedDropdownValue === "domain" ?  selectedDomains : [""];
        commonData["cname"] = this.$store.getters.cname;
        commonData["limit"] = this.filterLimit;

        const domainsTotalCharacters = commonData.domains.reduce((total, domain) => total + domain.length, 0);
        if (domainsTotalCharacters >= 1800) {
          this.$notification.error({message: this.$t('message.DomainCharacterLimitError')});
          this.isLoading = false;
          return;
        }
        const response = await axios({
          method: "POST",
          url: `domain/traffic_stats/access_logs/`,
          params: params,
          data: commonData,
        });
        this.isLoading = false;        
        this.totalAccessLogData = response.data.logs;
        this.accesslogData = this.totalAccessLogData.slice(0, 100);
        this.searchCount = response.data.total_count;
        this.count = true;
        this.totalPages = Math.ceil(this.searchCount / this.filterLimit);
      } catch (errors) {
        this.isLoading = false;
        this.$notification.error({ message: this.$t(`${errors.errors[0].slice(2)}`) });
        errors.forEach((message) => {
          this.flash(message, 'error', { timeout: 5000 });
        });
        return;
      }
    },
    changePage(newPage) {
     if (newPage >= 1 && newPage <= this.totalPages) {
      this.page = newPage;
      this.accesslogData = this.totalAccessLogData.slice(
        (this.page - 1) * 100, (this.page * 100)
      )
     }
    },
    getCommonData() {
      const domains = [];
      for (let d of this.selectedDomains) {
        domains.push(d.name);
      }

      const statusCodes = [];
      if (this.filterHttpStatusResponse.value === 'CUSTOM') {
        for (let s of this.selectCustomStatusCodes) {
          statusCodes.push(s.value);
        }
      }
      
      const requestTime = isEmpty(this.requestTimeOperatorValue.value.trim()) ? null : this.requestTimeOperatorValue;
      const bodyBytesSent = isEmpty(this.bodyBytesSentOperatorValue.value.trim()) ? null : this.bodyBytesSentOperatorValue;

      return {
        date: this.viewDate ? format(this.viewDate, 'yyyy-MM-dd') : null,
        from_time: this.fromTime,
        to_time: this.toTime,
        ip_address: this.filterIpAddress,
        http_status_response: this.filterHttpStatusResponse.value,
        status_codes: statusCodes,
        cache: this.filterCache,
        request_time: requestTime,
        body_bytes_sent: bodyBytesSent,
        domains: domains,
      };
    },
    async downloadLogs() {
      this.isLoading = true;

      const commonData = this.getCommonData();
      const fromTime = format(this.timeRange.start, 'yyyy-MM-dd HH:mm:ss');
      const toTime = format(this.timeRange.end, 'yyyy-MM-dd HH:mm:ss');
      const selectedDomains = this.selectedDomains.map((domain) => domain.name);
        
      commonData["from_time"] = fromTime;
      commonData["to_time"] = toTime;
      commonData["domains"] = this.selectedDropdownValue === "domain" ?  selectedDomains : [""];
      commonData["cname"] = this.$store.getters.cname;
      commonData["limit"] = this.filterLimit;
      let response;
      try {
        response = await axios({
          method: "POST",
          url: `domain/traffic_stats/download_access_logs/`,
          // responseType: 'blob',
          data: commonData
        });
      } catch (errors) {
        this.isLoading = false;
        this.$notification.error({ message: this.$t(`${errors.errors[0].slice(2)}`) });
        errors.forEach((message) => {
          this.flash(message, 'error', { timeout: 5000 });
        });
        return;
      }

      this.isLoading = false;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      let filename = `access_logs_${commonData.date}.csv`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    handleFilterClicked() {
      if (this.filterHttpStatusResponse.value === 'CUSTOM' && !this.selectCustomStatusCodes.length) {
        this.flash(this.$t('message.select_status_codes'), 'error', {"timeout": 5000});
        return;
      }

      this.fetchData();
    },
    handleDownloadLogsClicked() {
      if (this.filterHttpStatusResponse.value === 'CUSTOM' && !this.selectCustomStatusCodes.length) {
        this.flash(this.$t('message.select_status_codes'), 'error', {"timeout": 5000});
        return;
      }

      this.downloadLogs();
    },
    handleRequestTimeUpdate(data) {
      this.requestTimeOperatorValue = data;
    },
    handleBodyBytesSentUpdate(data) {
      this.bodyBytesSentOperatorValue = data;
    },
  }
}
</script>
