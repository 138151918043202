<template>
  <div class="threats-dashboard p-5">
    <div>
      <h3 class="mb-0">{{ $t('ThisMonth') }}</h3>
      <p>{{ this_month_text }}</p>
      <div class="row">
        <div class="col col-sm-12 col-md-6 col-lg-3 mb-2">
          <a-card class="h-100">
            <h4>{{ $t('DDoS') }}</h4>
            <p class="ant-statistic-title">{{ $t('ClusterAttacks') }}</p>
            <div class="d-flex align-items-center" style="color: #3f8600; font-size: 24px;">
              <a-icon class="ant-statistic-content-prefix" type="safety-certificate" />
              <p class="mb-0">{{ stats_this_month.ddos }} {{ $t('times') }}</p>
              <a-tooltip placement="right">
                <template slot="title">
                  <span>{{ $t('message.ClusterAttackMessage') }}</span>
                </template>
                <a-icon type="info-circle" :style="{ fontSize: '14px', marginLeft: '6px', }"/>
              </a-tooltip>
            </div>
          </a-card>
        </div>
        <div class="col col-sm-12 col-md-6 col-lg-3 mb-2">
          <a-card class="h-100">
            <h4>{{ $t('CCAttackMax') }}</h4>
            <p class="ant-statistic-title">{{ $t('message.NumberOfAttackQueriesPerSecond') }}</p>
            <div class="d-flex align-items-center" style="color: #cf1322; font-size: 24px;">
              <a-icon type="fire" :style="{ marginRight: '6px', }"/>
              <p class="mb-0">{{ this_month_cc_attacks }}</p>
              <span class="ml-2 font-sm">QPS</span>
            </div>
          </a-card>
        </div>
        <div class="col col-sm-12 col-md-6 col-lg-3 mb-2">
          <a-card class="h-100">
            <h4>{{ $t('Mitigated') }}</h4>
            <p class="ant-statistic-title">{{ $t('message.BlockedIPsOfAttackers') }}</p>
            <div class="d-flex align-items-center" style="color: #e19600; font-size: 24px;">
              <a-icon type="stop" :style="{ marginRight: '6px', }"/>
              <p class="mb-0">{{ this_month_blocked_ips }}</p>
              <span class="ml-2 font-sm">IPs</span>
            </div>
          </a-card>
        </div>
        <div class="col col-sm-12 col-md-6 col-lg-3 mb-2">
          <a-card class="h-100">
            <h4>{{ $t('TotalRequests') }}</h4>
            <p class="ant-statistic-title">{{ $t('message.NumberOfRequestsSentToYourCname') }}</p>
            <div class="d-flex align-items-center" style="color: #096dd9; font-size: 24px;">
              <a-icon type="dashboard" :style="{ marginRight: '6px', }"/>
              <p class="mb-0">{{ this_month_total_requests }} </p>
              <span class="ml-2 font-sm">/ {{ this_month_total_users }} users</span>
            </div>
          </a-card>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <h3 class="mb-0">{{ $t('Today') }}</h3>
      <p>{{ today_text }}</p>
      <div class="row">
        <div class="col col-sm-12 col-md-6 col-lg-3 mb-2">
          <a-card class="h-100">
            <h4>{{ $t('DDoS') }}</h4>
            <p class="ant-statistic-title">{{ $t('ClusterAttacks') }}</p>
            <div class="d-flex align-items-center" style="color: #3f8600; font-size: 24px;">
              <a-icon class="ant-statistic-content-prefix" type="safety-certificate" />
              <p class="mb-0">{{ today_ddos_attacks }}</p>
              <a-tooltip placement="right">
                <template slot="title">
                  <span>{{ $t('message.ClusterAttackMessage') }}</span>
                </template>
                <a-icon type="info-circle" :style="{ fontSize: '14px', marginLeft: '6px', }"/>
              </a-tooltip>
            </div>
          </a-card>
        </div>
        <div class="col col-sm-12 col-md-6 col-lg-3 mb-2">
          <a-card class="h-100">
            <h4>{{ $t('CCAttack') }}</h4>
            <p class="ant-statistic-title">{{ $t('message.NumberOfAttackQueriesPerSecond') }}</p>
            <div class="d-flex align-items-center" style="color: #cf1322; font-size: 24px;">
              <a-icon type="fire" :style="{ marginRight: '6px', }"/>
              <p class="mb-0">{{ today_cc_attacks }}</p>
              <span class="ml-2 font-sm">QPS</span>
            </div>
          </a-card>
        </div>
        <div class="col col-sm-12 col-md-6 col-lg-3 mb-2">
          <a-card class="h-100">
            <h4>{{ $t('Mitigated') }}</h4>
            <p class="ant-statistic-title">{{ $t('message.BlockedIPsOfAttackers') }}</p>
            <div class="d-flex align-items-center" style="color: #e19600; font-size: 24px;">
              <a-icon type="stop" :style="{ marginRight: '6px', }"/>
              <p class="mb-0">{{ today_blocked_ips }}</p>
              <span class="ml-2 font-sm">IPs</span>
            </div>
          </a-card>
        </div>
        <div class="col col-sm-12 col-md-6 col-lg-3 mb-2">
          <a-card class="h-100">
            <h4>{{ $t('TotalRequests') }}</h4>
            <p class="ant-statistic-title">{{ $t('message.NumberOfRequestsSentToYourCname') }}</p>
            <div class="d-flex align-items-center" style="color: #096dd9; font-size: 24px;">
              <a-icon type="dashboard" :style="{ marginRight: '6px', }"/>
              <p class="mb-0">{{ today_total_requests }} </p>
              <span class="ml-2 font-sm">/ {{ today_total_users }} users</span>
            </div>
          </a-card>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col col-sm-12 col-md-12 col-lg-6 mb-2">
          <a-card :title="$t('HTTPStatus')">
            <ThreatsHttpStatusPie/>
          </a-card>
        </div>
        <div class="col col-sm-12 col-md-12 col-lg-6">
          <ThreatsFakewallThreat/>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="row">
        <div class="col col-sm-12 col-md-12 col-lg-6">
          <a-card :title="$t('AttackMitigationHistory')">
            <ThreatsMitigationHistory/>
          </a-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import {format} from 'date-fns'

import ThreatsHttpStatusPie from '@/views/domain/charts/ThreatsHttpStatusPie';
import ThreatsFakewallThreat from '@/views/domain/charts/ThreatsFakewallThreat.vue';
import ThreatsMitigationHistory from '@/views/domain/charts/ThreatsMitigationHistory.vue';
import {getThreatsThisMonth, getThreatsToday} from '@/utilities/api';
import {EventBus, Events} from "@/plugins/event-bus.js";


export default {
  components: {ThreatsMitigationHistory, ThreatsFakewallThreat, ThreatsHttpStatusPie},
  async mounted() {
    const responseThisMonth = await getThreatsThisMonth();

    this.stats_this_month.ddos = parseInt(responseThisMonth.data.ddos);
    this.stats_this_month.cc = parseInt(responseThisMonth.data.cc);
    this.stats_this_month.mitigation = parseInt(responseThisMonth.data.mitigation);
    this.stats_this_month.request.request = parseInt(responseThisMonth.data.request.request);
    this.stats_this_month.request.user = responseThisMonth.data.request.user === "null" ? null : parseInt(responseThisMonth.data.request.user);

    const responseToday = await getThreatsToday();
    this.stats_today.ddos = parseInt(responseToday.data.ddos);
    this.stats_today.cc = parseInt(responseToday.data.cc);
    this.stats_today.mitigation = parseInt(responseToday.data.mitigation);
    this.stats_today.request.request = parseInt(responseToday.data.request.request);
    this.stats_today.request.user = responseToday.data.request.user === "null" ? null : parseInt(responseToday.data.request.user);
  },
  data() {
    return {
      stats_this_month: {
        "ddos": 0,
        "cc": 0,
        "mitigation": 0,
        "request": {
          "request": 0,
          "user": null,
        },
      },
      stats_today: {
        "ddos": 0,
        "cc": 0,
        "mitigation": 0,
        "request": {
          "request": 0,
          "user": null,
        },
      },
    }
  },
  beforeDestroy() {
    EventBus.$off(Events.DOWNLOAD_CERTIFICATE);
  },
  computed: {
    this_month_text() {
      return format(new Date(), 'LLLL');
    },
    today_text() {
      return format(new Date(), 'LLLL d, yyyy');
    },
    this_month_cc_attacks() {
      return numeral(this.stats_this_month.cc).format('0 a');
    },
    this_month_blocked_ips() {
      return numeral(this.stats_this_month.mitigation).format('0 a');
    },
    this_month_total_requests() {
      return numeral(this.stats_this_month.request.request).format('0 a') + ' reqs';
    },
    this_month_total_users() {
      return numeral(this.stats_this_month.request.user).format('0 a');
    },
    today_ddos_attacks() {
      if (this.stats_today.ddos > 0) {
        return this.stats_today.ddos + ' times';
      }

      return this.$t('message.NotUnderAttack');
    },
    today_cc_attacks() {
      return numeral(this.stats_today.cc).format('0 a');
    },
    today_blocked_ips() {
      return numeral(this.stats_today.mitigation).format('0 a');
    },
    today_total_requests() {
      return numeral(this.stats_today.request.request).format('0 a') + ' reqs';
    },
    today_total_users() {
      return numeral(this.stats_today.request.user).format('0 a');
    },
  },
}
</script>
