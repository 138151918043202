<template>
  <a-list size="small" item-layout="horizontal" :data-source="source" :loading="loading">
    <a-list-item slot="renderItem" slot-scope="item">
      <div class="filter-control">
        <div class="control offset-gap">
          <div v-if="isSelected(item[valueKey])"><a-button @click="handleFilterClick(item[valueKey])" size="small">{{ $t('RemoveFilter') }}</a-button></div>
          <div v-else>
            <a-button @click="handleFilterClick(item[valueKey])" size="small">{{ $t('Filter') }}</a-button>
            <a-button @click="handleExcludeClick(item[valueKey])" size="small" class="ml-1" type="danger">
              {{ $t('Exclude') }}
            </a-button>
          </div>
        </div>
        <div class="value">
          <span class="pr-4">{{ displayName(item) }}</span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <span :title="formatHits(item.hits, true)" class="text-black-50">{{ formatHits(item.hits) }}</span>
        <span class="ml-1 d-flex"><progress :value="item.hits" :max="totalHits">{{
            (item.hits / totalHits) *
            100
          }}%</progress></span>
      </div>
    </a-list-item>
  </a-list>
</template>

<script>
import numeral from 'numeral';

export default {
  props: ['source', 'valueKey', 'loading', 'selectionStore', 'displayKey'],
  computed: {
    totalHits() {
      let total = 0;

      for (let i of this.source) {
        total += i.hits;
      }

      return total;
    },
  },
  methods: {
    displayName(item) {
      if (this.displayKey) {
        return item[this.displayKey];
      }
      
      return item[this.valueKey];
    },
    handleFilterClick(value) {
      this.$emit('filter', value);
    },
    handleExcludeClick(value) {
      this.$emit('exclude', value);
    },
    formatHits(value, forTitle = false) {
      if (forTitle) {
        return numeral(value).format('0,0');
      }
      
      if (value < 1000) {
        return value;
      }

      return numeral(value).format('0.0a');
    },
    isSelected(value) {
      // We are only checking the filters list because any value that is excluded
      // won't appear in the list in the first place, so we don't need to 
      // display "Remove exclude" in the UI. 
      return this.selectionStore.filters[this.valueKey].includes(value);
    },
  }
}
</script>

<style scoped>
.filter-control {
  display: flex;
  flex-direction: column;
}

.filter-control .control {
  display: none;
}

.filter-control:hover .control {
  display: block;
}

.filter-control:hover .value {
}

.value > span {
  line-break: anywhere;
}

.ant-list-item {
  align-items: baseline;
}

.offset-gap {
  margin-bottom: -1.4rem !important;
}
</style>