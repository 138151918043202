<template>
  <a-table :columns="columns" :data-source="domains" :bordered="true">
  </a-table>
</template>

<script>
import {getThreatsFakewallThreat} from '@/utilities/api';

export default {
  async mounted() {
    const response = await getThreatsFakewallThreat();
    
    this.domains = response.data.map((d) => ({domain: d}));
  },
  data() {
    return {
      columns: [
        {
          dataIndex: 'domain',
          key: 'domain',
          title: this.$t('FakewallTreats')
        },
      ],
      domains: []
    }
  }
}
</script>