<template>
  <div class="operator-value-field">
    <div>
      <CSelect
               :id="selectOperatorId"
               style="margin-bottom: 0"
               :value.sync="operator"
               :options="operators"/>
    </div>
    <div>
      <CInput
              :id="inputOperatorId"
              type="number"
              style="margin-bottom: 0"
              v-model="value"/>
    </div>
  </div>
</template>

<style scoped>
.operator-value-field {
  display: flex;
}

.operator-value-field div:first-child {
  margin-right: 6px;
}
</style>

<script>

const EQUAL_TO_OPERATOR = '=';
const LESS_THAN_EQUAL_TO_OPERATOR = '<=';
const GREATER_THAN_EQUAL_TO_OPERATOR = '>=';

export const VALUE_DEFAULT = {operator: EQUAL_TO_OPERATOR, value: ""};

export default {
    props: {
        selectOperatorId: String,
        inputOperatorId: String
  },
  data() {
    return {
      operator: EQUAL_TO_OPERATOR,
      operators: [
        EQUAL_TO_OPERATOR,
        LESS_THAN_EQUAL_TO_OPERATOR,
        GREATER_THAN_EQUAL_TO_OPERATOR,
      ],
      value: ""
    };
  },
  watch: {
    operator: function () {
      this.emitChanged();
    },
    value: function () {
      this.emitChanged();
    },
  },
  methods: {
    emitChanged() {
      this.$emit('change', {
        operator: this.operator,
        value: this.value
      });
    },
  }
}
</script>