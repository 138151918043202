<template>
  <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
  />
</template>

<script>
import numeral from 'numeral';
import {Line as LineChartGenerator} from 'vue-chartjs/legacy';

export default {
  components: {
    LineChartGenerator
  },
  props: {
    intervals: {
      default: [],
    },
    loading: {
      default: false,
    },
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 250
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {}
  },
  computed: {
    chartOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.$t('MitigatedVsNotMitigatedRequest'),
          },
          legend: {
            position: 'bottom',
          },
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              callback: function (value) {
                if (value < 1000)
                  return value;

                return numeral(value).format('0.0a');
              }
            },
          }
        }
      };
    },
    chartData: function () {
      return {
        labels: this.intervals.map((i) => i.second),
        datasets: [
          {
            label: this.$t('Mitigated'),
            backgroundColor: this.loading ? '#a6a6a6' : '#0161ff',
            borderColor: this.loading ? '#a6a6a6' : '#0161ff',
            data: this.intervals.map((i) => i.mitigated)
          },
          {
            label: this.$t('NotMitigated'),
            backgroundColor: this.loading ? '#a6a6a6' : '#ff0000',
            borderColor: this.loading ? '#a6a6a6' : '#ff0000',
            data: this.intervals.map((i) => i.not_mitigated)
          },
        ],
      };
    },
  }
}
</script>