<template>
    <div class="top-container">
      <div class="d-flex justify-content-end">
        <CButton 
                   size="auto"
                   class="btn_search btn-primary"
                   @click="downloadUserAuditLogs">
            {{ $t('Download CSV') }}
        </CButton>
      </div>
      <div class="options-container">
      
        <div class="date-picker-container option-item">
          <slideout-panel></slideout-panel>
          <div :class="{ 'time-range-display': true, 'loading': isLoading }">
            <div class="time-range-control" @click="showPanel">
              <!-- <CIcon name="cil-clock" style="margin-right: 6px;"/> -->
              <div class="text-display">
                <span class="time-range">{{ timeRangeDisplay }}</span>
              </div>
            </div>
          </div>  
        </div>
        <div class="searchInput d-flex align-items-center mr-2">
             <multiselect
               id="multiselect_domain"
               v-model="selectedDomains"
               :options="domainsOptions"
               group-values="domains"
               group-label="name"
               :group-select="true"
               label="name"
               track-by="pk"
               :loading="domainData.loading"
               :multiple="true"
               :close-on-select="false"
               @search-change="searchDomain">
                <template slot="afterList">
                  <span class="load-more" @click="$emit('loadMoreDomain')" v-if="domainData.hasNext">{{
                      $t('load_more')
                    }}</span>
                </template>
                <span slot="noResult">{{ $t('message.domain_not_found') }}</span>
              </multiselect>
        </div>
        <div class="d-flex justify-content-end">
          <CButton 
                   size="auto"
                   class="btn btn-primary"
                   @click="fetchUserAuditLogs(true)">
            {{ $t('Search') }}
          </CButton>
        </div>
        
      </div>
  
      
      <div class="top-overview-container">
        <table class="table">
            <thead class="thead-light">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Action</th>              
                <th scope="col">Domain</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="auditLog in auditLogsData">
                  <tr class="parent" :id="'row' + auditLog.id">            
                    <th>{{ $moment(auditLog.action_time).format('MMM D, YYYY') }}</th>
                    <td>{{ auditLog.changes_data?.action }}</td>                  
                    <td>{{ auditLog.changes_data?.domain_name }}</td>
                    <td class="cur-ptr" @click="showChildRows(auditLog.id)">                    
                      <i :id="'collapsible_right' + auditLog.id" class="icon-font bx bx-caret-right"></i>
                      <i :id="'collapsible_down' + auditLog.id" class="collapsible_down icon-font bx bx-caret-down"></i>                    
                    </td>              
                  </tr>
                  <tr :class="'d-none child-row' + auditLog.id">
                      <td class="border-none" colspan="4">
                          <b>Date:</b> {{ auditLog.action_time }}
                      </td> 
                  </tr>
                  <tr :class="'d-none child-row' + auditLog.id">
                      <td class="border-none" colspan="4">
                          <b>User IP Address:</b> {{ auditLog.changes_data?.client_ip }}
                      </td>
                  </tr>
                  <tr :class="'d-none child-row' + auditLog.id">
                      <td class="border-none" colspan="4">
                          <b>Resource:</b> {{ auditLog.changes_data?.domain_name }}
                      </td>
                  </tr>
                  <tr :class="'d-none child-row' + auditLog.id">
                      <td class="border-none" colspan="4">
                          <b>Audit Record:</b> {{ auditLog.object_id }}
                      </td>
                  </tr>
              </template>
            </tbody>
      </table>
      </div>
      </div>
  </template>
  
  <style scoped>
  .total-graph {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  .play-button {
    margin: 0 auto;
    top: 25%;
    position: relative;  
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.5em 0 0.5em 1em;
    border-color: transparent transparent transparent #2834b7;
    opacity: .75; 
  }
  .top-container {
    padding: 25px;
  }
  .searchInput .form-control {
    height: 43px !important;
    width: 500px;
    
  }
  .searchInput1{
    padding: 10px;
    font-size: 17px;
    margin-left: 5px;
    float: left;
    width: 700px;
  }
  
  .options-container {
    display: flex;
    flex-direction: row;
  }
  
  .option-item {
    padding: 10px;
  }
  
  .top-overview-container, .overview-container {
    margin-top: 20px;
  }
  
  header {
    display: flex;
    flex-direction: column;
  }
  
  .date-picker-container {
    display: inline-block;
    /* margin-top: 6px; */
  }
  .btn{
    width: 103px;
    height: 40px !important;
    margin-top: 9px;
  }
  .btn_search{
    width: auto;
    height: 40px !important;
    margin-top: 11px;
    border-color:rgb(8, 50, 141);
    background-color:white;
    color:rgb(8, 50, 141);
  }
  
  .top-overview-container .multiselect__content .load-more {
    font-size: 0.9em;
    margin-left: 12px;
    color: #39f;
    cursor: pointer;
  }
  
  .top-overview-container .multiselect__content .load-more:hover {
    text-decoration: underline;
  }
  
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .opacity-0 {
    opacity: 0;
  }
  
  .spinner-container span {
    margin-left: 10px;
  }
  
  .time-range-label {
    margin-bottom: 5px;
  }
  
  .time-range-display.loading .time-range-control {
    color: gray;
    background-color: #fbfbfb;
    
  }
  
  .time-range-display.loading .time-range-control {
    cursor: not-allowed;
  }
  
  .time-range-display .time-range-control {
    display: inline-flex;
    align-items: center;
    color: #321fdb;
    cursor: pointer;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 110%;
  }
  
  .time-range-display .time-range-control:hover {
    background-color: #fbfbfb;
  }
  
  .text-display {
    display: flex;
    flex-direction: column;
  }
  
  .time-range-display .time-range {
    font-size: 0.9em;
    font-weight: normal;
    color: gray;
  }
  
  .option__title.all span {
    display: block;
  }
  
  .option__title.all span:first-child {
    font-weight: 500;
  }
  
  .option__title.all .description {
    font-size: 0.9em;
    margin-top: 5px;
  }
  
  .select-cname {
    display: inline-block;
  }
  </style>
  
  <style>
  .select-cname label {
    margin-bottom: 5px;
  }
  
  .select-cname select {
    height: 61px;
  }
  
  .border-none {
      border-top: 0 !important;
      padding: 4px !important;
      padding-left: 0.5rem !important;
  }
  
  .collapsible {
      cursor: pointer;
      width: 100%;
      border: none;
      text-align: left;
      outline: none;
      font-size: 15px;
  }
  
  .collapsible:after {
      content: '\002B';
      color: #321fdb;
      font-weight: bold;
      float: right;
      margin-left: 5px;
      font-size: 16px;
  }
  
  .log-active:after {
      content: "\2212";
  }
  
  .collapsible_down {
      display: none;
  }
  
  .icon-font {    
      font-size: 18px;
  }
  
  .cur-ptr {
      cursor: pointer;
  }
  </style>
  
  <script>
  
  import 'boxicons'
  import 'boxicons/css/boxicons.min.css'
  
  import {isEmpty} from 'lodash';
  import {
    format,
    addSeconds,
    addMinutes,
    subSeconds,
    subMinutes,
    parseISO,
    differenceInMinutes
  } from 'date-fns';
  import numeral from 'numeral';
  import Multiselect from 'vue-multiselect';
  
  import axios from '@/plugins/axios';
  import {ADJUSTMENT_TYPE, TIME_ADJUSTMENTS, TIME_ADJUSTMENTS_TRANSLATIONS} from './TimeRangePicker';
  import OverviewDomainsTable from "@/views/domain/charts/OverviewDomainsTable";
  import {subscriptionPlan, getUserAuditLogs} from "@/utilities/api";
  import {UNCOVERED_NETWORK_TRAFFIC} from "@/utilities/constants";
  import {EventBus, Events} from "@/plugins/event-bus.js";
  
  const SHANGHAI_UTC_OFFSET_MINUTES = 480;
  
  // We do a two-step conversion to get to Asia/Shanghai.
  // First, we convert the user's time to UTC.
  // Second, from UTC convert to Asia/Shanghai.
  // We do it this way to make our base UTC.
  // Important! We do not change the date object's timezone information. Even though we convert the "time", the timezone
  // will still be the user's timezone (system timezone)
  function toAliyunTimezone(date) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: "Asia/Singapore"}));
    // const offsetMinutes = new Date().getTimezoneOffset();
  
    // const utc = addMinutes(date, offsetMinutes);
  
    // return addMinutes(utc, SHANGHAI_UTC_OFFSET_MINUTES);
  }
  
  function fromAliyunTimezone(date) {
    const offsetMinutes = new Date().getTimezoneOffset();
  
    const aliyunTime = subMinutes(date, SHANGHAI_UTC_OFFSET_MINUTES);
  
    return subMinutes(aliyunTime, offsetMinutes);
  }
  
  function parseISOtoSystemTime(date) {
    return fromAliyunTimezone(parseISO(date));
  }
  
  function addUncoveredNetworkTraffic(total) {
    return total + (total * (UNCOVERED_NETWORK_TRAFFIC / 100));
  }
  
  const CHART_DATE_FORMAT = 'MMM d, yyyy';
  // const CHART_TIME_FORMAT = 'h:mm:ss aaa';
  const CHART_FULL_DATE_FORMAT = `${CHART_DATE_FORMAT}`;
  
  function getLabelsDatapoints(data) {
    const datapoint = [];
    const labels = [];
  
    let lastDate = null;
    for (let d of data) {
      const currentIterationDate = format(d.t, CHART_DATE_FORMAT);
  
      // this bit will conditionally display the date. this will only show the full date and time if it
      // is the current iteration or the date data between iteration has changed.
      // e.g ['Nov 21, 2022, 11:58:00 pm', '11:59:00 pm', 'Nov 22, 2022, 12:00:00 am', '12:01:00 am']
      let displayFormat = CHART_TIME_FORMAT;
      if (lastDate !== currentIterationDate) {
        displayFormat = CHART_FULL_DATE_FORMAT;
        lastDate = currentIterationDate;
      }
  
      labels.push(format(d.t, displayFormat));
      datapoint.push(d.y);
    }
  
    return {labels, datapoint}
  }
  
  export default {
    props: ['domainData', 'searchDomain'],
    components: {
      OverviewDomainsTable,
      Multiselect
    },
    mounted() {
      this.$store.commit('resetTimeRangePicker');    
      this.fetchUserAuditLogs(true);
      this.scroll();
    },
    beforeDestroy() {
    EventBus.$off(Events.DOWNLOAD_CERTIFICATE);
    },
    watch: {
      selectedDomain: function () {
        if (this.selectedDomain && (this.selectedDomain.pk === this.allToken)) {
          this.fetchUserAuditLogs(true);
        } else {
          this.fetchUserAuditLogs();
        }
      }
    },
    data() {
      const allToken = '__all__';
  
      return {
        selectedDomain: {
          pk: allToken,
          name: this.$t('domain.AllDomains')
        },
        selectedDomains: [],
        trafficDomainData: [],
        trafficCnameData: [],
        isLoading: false,
        timeRangePanel: null,
        allToken: allToken,
        seriesInfo: {
          interval: 15,
          time_unit: 's'
        },
        subscriptions: [],
        auditLogsData: [],
        page: 1
      }
    },
    computed: {
      domainsOptions: function () {
        return [
          {
            name: this.$t('SelectAll'),
            domains: Object.freeze(this.domainData.domains)
          }
        ];
      },
      userCname: {
        get() {
          let cname = this.$store.getters.cname;
          return cname;
        },
        set(value) {
          this.$store.state.selectedSubscription = value;
        }
      },
      timeRange: {
        get() {
          return this.$store.state.timeRangePicker.range;
        },
        set(value) {
          this.$store.state.timeRangePicker.range = value;
        }
      },
      timeRangeDisplay: function () {
        const timeFormat = 'MMM d, yyyy';
  
        return format(this.timeRange.start, timeFormat) + '→' + format(this.timeRange.end, timeFormat);
      },
      isViewByCname: function () {
        if (!this.selectedDomain)
          return false;
  
        return this.selectedDomain.pk === this.allToken;
      },
      selectedDomainViewBy: function () {
        if (!this.selectedDomain)
          return null;
  
        if (this.isViewByCname)
          return this.userCname;
  
        return this.selectedDomain.name;
      }
    },
    methods: {
      scroll () {
        window.onscroll = () => {
          let bottomOfWindow = (
              (document.documentElement.scrollHeight - document.documentElement.scrollTop) < document.documentElement.offsetHeight
          )
          
          if (bottomOfWindow) {
              this.page++
              if(this.rawObj.next){
                  this.fetchUserAuditLogs()
              }
          }
        }
      },
      async fetchUserAuditLogs(isSearched=false) {
          let response;
  
          if  (isSearched) {
              this.page = 1;
              this.auditLogsData = [];
          }
          
          let domains = null;
  
          if (this.selectedDomains.length > 0) {
              this.auditLogsData = [];
              let searchDomains = [];            
              for (var i=0; i < this.selectedDomains.length; i++) {
                  searchDomains.push(this.selectedDomains[i].name);
              };
              domains = {
                  domains: searchDomains
              };
          }
          
          try {
              response = await axios.post(
                `domain/log-entry/`,
                domains,
                {
                  params: {
                      start: format(toAliyunTimezone(this.timeRange.start), 'yyyy-MM-dd HH:mm:ss'),
                      end: format(toAliyunTimezone(this.timeRange.end), 'yyyy-MM-dd HH:mm:ss'),
                      page: this.page
                  }
                }
              );
          } catch (errors) {
              errors.forEach((message) => {
                  this.flash(message, 'error', {"timeout": 5000});
              });
              return;
          }
  
          this.rawObj = response.data;
          this.auditLogsData.push(...response.data.results);
      },
      async downloadUserAuditLogs() {
        this.isLoading = true;
  
        let domains = null;
  
        if (this.selectedDomains.length > 0) {
          let searchDomains = [];
          for (var i=0; i < this.selectedDomains.length; i++) {
              searchDomains.push(this.selectedDomains[i].name);
          };
          
          domains = {
              domains: searchDomains
          };
        }
        
        let response;
        
        response = await axios.post(
            `domain/log-entry/download_user_audit_logs/`,
            domains,
            {
              params: {
                  start: format(toAliyunTimezone(this.timeRange.start), 'yyyy-MM-dd HH:mm:ss'),
                  end: format(toAliyunTimezone(this.timeRange.end), 'yyyy-MM-dd HH:mm:ss')
              }
            },
            {
              responseType: 'blob'
            }
          );
  
        this.isLoading = false;
  
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
  
        let filename = `user_audit_logs.csv`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      },
      showPanel() {
        if (this.isLoading) {
          return;
        }
  
        if (this.timeRangePanel) {
          this.timeRangePanel.show();
  
          return;
        }
  
        const handleApply = async () => {
          if (this.timeRangePanel) {
            await this.timeRangePanel.hide();
          }
          
          await this.fetchUserAuditLogs(true);
        };
  
        this.timeRangePanel = this.$showPanel({
          component: 'app-time-range-picker',
          openOn: 'left',
          cssClass: 'timerange-slideout',
          width: 400,
          keepAlive: true,
          props: {
            onApply: handleApply,
            limited: false
          }
        });
      },
      showChildRows(auditLogId) {
          let classes = document.getElementsByClassName('child-row' + auditLogId);
          for (let class_el in classes) {
              if (classes[class_el].style) {
                  if (classes[class_el].classList.contains('d-none')) {
                      classes[class_el].classList.remove('d-none');
                      document.getElementById('collapsible_right' + auditLogId).style.display = 'none';
                      document.getElementById('collapsible_down' + auditLogId).style.display = 'block';
                  } else {
                      classes[class_el].classList.add('d-none');                    
                      document.getElementById('collapsible_down' + auditLogId).style.display = 'none';
                      document.getElementById('collapsible_right' + auditLogId).style.display = 'block';
                  }
              }            
          }
      }
    }
  }
  </script>